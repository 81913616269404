import { Autocomplete, Box, Typography } from '@octanner/prism-core'
import React from 'react'
import { Control, Controller, FieldErrorsImpl } from 'react-hook-form'
import { FormData } from './ActionForm'
import { EventOption } from './utils'

interface Props {
  filteredEventOptions: EventOption[]
  control: Control<FormData>
  errors: Partial<FieldErrorsImpl<FormData>>
}

const ActionInput = ({ filteredEventOptions, control, errors }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
      }}
    >
      <Typography variant="h3">Add action</Typography>
      <Controller
        name="taskScheduleGroup"
        control={control}
        rules={{ required: 'This field is required' }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            disablePortal
            dataTestId="action-form:action-input"
            error={!!errors.taskScheduleGroup}
            helperText={errors.taskScheduleGroup?.message}
            label="Action"
            options={filteredEventOptions}
            onChange={(_, data) => field.onChange(data)}
          />
        )}
      />
    </Box>
  )
}

export default ActionInput
