import { Button, TextField } from '@octanner/prism-core'
import { CelebrationGroupSettings } from '@octanner/yearbook-program-settings'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Root } from './Styled'
import { useFlags } from 'launchdarkly-react-client-sdk'
type HydratedProps = { programId: string; celebrationGroupId: string }

type Props = HydratedProps | {}

const isHydratedProps = (object: any): object is HydratedProps =>
  'programId' in object

const getProps = (props: Props) => {
  if (!isHydratedProps(props)) return {}
  const { programId, celebrationGroupId } = props
  return { programId, celebrationGroupId }
}

const CelebrationSettingsWrapper: React.FC<Props> = (props) => {
  const history = useHistory()
  const { ccAdminCcybClgSettingsD241017 } = useFlags()
  const { programId, celebrationGroupId } = getProps(props)
  const [form, setForm] = useState({
    programId: programId || '',
    celebrationGroupId: celebrationGroupId || '',
    submitted: false,
  })

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    setForm((cur) => ({ ...cur, submitted: true }))
    if (!form.celebrationGroupId || !form.programId) return
    history.push(
      `/celebration-settings/${form.programId}/${form.celebrationGroupId}`
    )
  }

  const onChange = () => {
    history.push(`/program-settings/${programId}`)
  }

  return (
    <Root>
      <Form onSubmit={handleSubmit}>
        <TextField
          label="Program Id"
          value={form.programId}
          onChange={(e) =>
            setForm((cur) => ({ ...cur, programId: e.target.value }))
          }
          error={form.submitted && !form.programId}
        />
        <TextField
          label="Celebration Group Id"
          value={form.celebrationGroupId}
          onChange={(e) =>
            setForm((cur) => ({ ...cur, celebrationGroupId: e.target.value }))
          }
          error={form.submitted && !form.celebrationGroupId}
        />
        <Button type="submit">Submit</Button>
      </Form>
      {programId && celebrationGroupId && (
        <CelebrationGroupSettings
          programId={programId}
          celebrationGroupId={celebrationGroupId}
          onChange={onChange}
          launchDarklyFlags={[
            {
              key: 'ccAdminCcybClgSettingsD241017',
              value: ccAdminCcybClgSettingsD241017,
            },
          ]}
        />
      )}
    </Root>
  )
}

export default CelebrationSettingsWrapper
