import { Button, Typography, Modal } from '@octanner/prism-core'
import React from 'react'

type Props = {
  open: boolean
  onClose: () => void
  error: { message: string } | null
  successMessage: string
}

export const ResultModal = ({
  open,
  onClose,
  error,
  successMessage,
}: Props) => (
  <Modal
    id="create-celebration-result-modal"
    open={open}
    title={
      error?.message.length > 0
        ? 'System error'
        : 'Successfully Created Celebration'
    }
    actions={<Button onClick={onClose}>Close</Button>}
    onClose={onClose}
  >
    {error?.message.length > 0 ? (
      <>
        <Typography>{error.message}</Typography>
      </>
    ) : (
      <>
        <Typography>{successMessage}</Typography>
      </>
    )}
  </Modal>
)
