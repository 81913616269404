import React, { createContext, useContext, useReducer } from 'react'
import { CelebrationLevelConfig } from '../common/models/CelebrationLevelConfig'
import { CelebrationLevelGroupConfig } from '../common/models/CelebrationLevelGroupConfig'
import { ContextData, Dispatch } from '../models/GroupSettingsContext'
import SettingsReducer from './reducer'

interface SettingsProviderProps {
  children: React.ReactNode
  celebrationGroup: CelebrationLevelGroupConfig
  usedYears: string[]
}

export const GroupSettingsContext = createContext<ContextData>(undefined)

export const GroupSettingsDispatch = createContext<Dispatch>(undefined)

export function GroupSettingsContextProvider({
  children,
  celebrationGroup,
  usedYears,
}: SettingsProviderProps) {
  const selectedYears = (celebrationGroup?.celebrationLevelConfigs ?? []).map(
    (c: CelebrationLevelConfig) => c.rank.toString()
  )
  const initialState: ContextData = {
    ...celebrationGroup,
    usedYears,
    selectedYears,
  }
  const [state, dispatch] = useReducer(SettingsReducer, initialState)

  return (
    <GroupSettingsContext.Provider value={state}>
      <GroupSettingsDispatch.Provider value={dispatch}>
        {children}
      </GroupSettingsDispatch.Provider>
    </GroupSettingsContext.Provider>
  )
}

export function useGroupSettings() {
  return useContext(GroupSettingsContext)
}

export function useGroupSettingsDispatch() {
  return useContext(GroupSettingsDispatch)
}
