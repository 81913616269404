import {
  Alert,
  Box,
  Button,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
} from '@octanner/prism-core'
import { Close } from '@octanner/prism-icons'
import useDeleteActionScheduleTemplate from '../../hooks/useDeleteActionScheduleTemplate'
import React, { useRef, useState, useEffect } from 'react'
import { useGroupSettingsDispatch } from '../../contexts/GroupSettingsContext'
import useCreateActionScheduleTemplate from '../../hooks/useCreateActionScheduleTemplate'
import { useGetActionScheduleTemplatesByCLGId } from '../../hooks/useGetActionScheduleTemplatesByCLGId'
import usePatchCelebrationConfig from '../../hooks/usePatchCelebrationConfig'
import ActionForm, { EditFormData, FormData } from '../ActionForm/ActionForm'
import ConfirmationModal from '../ConfirmationModal'
import CelebrationGroupSchedule from './CelebrationGroupSchedule'
import { buildStartOffset } from './utils'
import { YbActionScheduleTemplateRepresentation } from '../../models/ActionScheduleTemplate'
import useUpdateActionScheduleTemplate from '../../hooks/useUpdateActionScheduleTemplate'
import { TemplateUUIDs } from '../CelebrationGroupEdit'
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors'
import { YbCelebrationLevelGroupConfigInput } from '../../common/models/YbCelebrationLevelGroupConfigInput'
import { CelebrationFrequency } from '../../common/models/ProgramRepresentation'

interface Props {
  celebrationLevelGroupUuid: string
  displayHeading?: boolean
  showToggleScheduleBtn?: boolean
  programId: string
  setAstsToPossiblyDelete?: React.Dispatch<React.SetStateAction<TemplateUUIDs>>
  celebrationFrequency?: CelebrationFrequency
  batchDay?: number
}

const CelebrationGroupActionScheduleContainer = ({
  celebrationLevelGroupUuid,
  displayHeading = false,
  showToggleScheduleBtn = false,
  programId,
  setAstsToPossiblyDelete,
  celebrationFrequency,
  batchDay,
}: Props) => {
  const [isAddActionOpen, setIsAddActionOpen] = useState(false)

  const [alert, setAlert] = useState<{
    type: 'error' | 'warning' | 'info' | 'success' | 'secondary'
    message: string
    list?: string[]
  } | null>(null)

  const [deleteActionState, setDeleteActionState] = useState({
    isOpen: false,
    name: '',
    actionScheduleTemplateUuid: '',
  })

  const { patchCelebrationConfig, loading: patchConfigLoading } =
    usePatchCelebrationConfig()

  const dispatch = useGroupSettingsDispatch()

  const { actionScheduleTemplates, loading: getAstLoading } =
    useGetActionScheduleTemplatesByCLGId(celebrationLevelGroupUuid)

  const { deleteActionScheduleTemplate, loading: deleteASTLoading } =
    useDeleteActionScheduleTemplate()

  const {
    createActionScheduleTemplate,
    loading: createAstLoading,
    error: createAstError,
  } = useCreateActionScheduleTemplate()

  const {
    updateActionScheduleTemplate,
    loading: updateAstLoading,
    error: updateAstError,
  } = useUpdateActionScheduleTemplate()

  const [editingTemplate, setEditingTemplate] =
    useState<YbActionScheduleTemplateRepresentation | null>(null)

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)

  const resetDeleteActionState = () => {
    setDeleteActionState({
      isOpen: false,
      name: '',
      actionScheduleTemplateUuid: '',
    })
  }

  useEffect(() => {
    if (actionScheduleTemplates.length > 0 && setAstsToPossiblyDelete) {
      const peerNotes = actionScheduleTemplates
        .filter((template) =>
          [
            'NOTIFY_CELEBRANT_INVITE_PEERS',
            'REGISTER_INVITES',
            'REQUEST_PEERS_PERSONAL_NOTE',
          ].includes(template.taskScheduleGroup.code)
        )
        .map((template) => template.actionScheduleTemplateUuid)

      const leaderNotes = actionScheduleTemplates
        .filter((template) =>
          ['REQUEST_MANAGER_PERSONAL_NOTE'].includes(
            template.taskScheduleGroup.code
          )
        )
        .map((template) => template.actionScheduleTemplateUuid)

      const yearbook = actionScheduleTemplates
        .filter((template) =>
          [
            'NOTIFY_CELEBRANT_INVITE_PEERS',
            'REGISTER_INVITES',
            'REQUEST_PEERS_PERSONAL_NOTE',
            'REQUEST_MANAGER_PERSONAL_NOTE',
            'NOTIFY_CELEBRANT_VIEW_YEARBOOK',
            'NOTIFY_ADMIN_EMPTY_YEARBOOK',
          ].includes(template.taskScheduleGroup.code)
        )
        .map((template) => template.actionScheduleTemplateUuid)

      setAstsToPossiblyDelete({
        peerNotes,
        leaderNotes,
        yearbook,
      })
    }
  }, [actionScheduleTemplates, setAstsToPossiblyDelete])

  useEffect(() => {
    // eslint disable wasn't working, when you remove these console logs,
    // go to the eslintrc.yml file and remove the no-console: off rule, and uncomment the line below it to reinstate our previous rules
    if (createAstError !== undefined) console.log({ createAstError })
    if (updateAstError !== undefined) console.log({ updateAstError })
  }, [createAstError, updateAstError])

  const listErrors = (errorMessage: string) => {
    const finalErrMsgArr: string[] = []
    const errMsgArr: string[] = errorMessage.split(', ')
    if (errMsgArr.length % 2 === 1) {
      return errMsgArr
    }
    for (let i = 0; i < errMsgArr.length; i += 2) {
      const temp: string = errMsgArr[i] + ': ' + errMsgArr[i + 1]
      finalErrMsgArr.push(temp)
    }

    return finalErrMsgArr
  }

  const targetRef = useRef(null)
  const scrollToTarget = () => {
    targetRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  useEffect(() => {
    scrollToTarget()
  }, [alert])

  const buildConfig = (formInput: FormData | EditFormData) => {
    let config: YbCelebrationLevelGroupConfigInput = {
      id: celebrationLevelGroupUuid,
    }
    if (formInput?.surveyUrl) {
      dispatch({ type: 'setSurveyUrl', surveyUrl: formInput.surveyUrl })
      config = { ...config, surveyUrl: formInput.surveyUrl }
    }
    if (formInput?.attentionTo) {
      dispatch({
        type: 'selectShipToAttention',
        shipToAttentionType: formInput.attentionTo,
      })
      config = {
        ...config,
        shipToAttentionType: formInput.attentionTo,
      }
    }
    if (formInput?.shippingAddress) {
      dispatch({
        type: 'selectShipToAddress',
        shipToAddressType: formInput.shippingAddress,
      })
      config = {
        ...config,
        shipToAddressType: formInput.shippingAddress,
      }
    }
    if (formInput?.billingLocation) {
      dispatch({
        type: 'selectBillToLocation',
        billToLocationType: formInput.billingLocation,
      })
      config = {
        ...config,
        billToLocationType: formInput.billingLocation,
      }
    }
    return config
  }

  const handleSubmit = async (formInput: FormData) => {
    await patchCelebrationConfig({
      config: buildConfig(formInput),
      programId: programId,
    })

    await createActionScheduleTemplate({
      input: {
        celebrationLevelGroupUuid,
        // @ts-ignore - we do form validation to ensure this is not an empty string
        taskScheduleGroup: formInput.taskScheduleGroup.value,
        startOffset: buildStartOffset(formInput.startOffset, formInput.when),
        offsetFrom: formInput.offsetFrom,
        addInternationalShippingOffset:
          formInput.addInternationalShippingOffset,
        respectsPrivacy: false,
        endOffset: 0,
      },
      onSuccess: () => {
        setAlert({
          type: 'success',
          message: `${formInput.taskScheduleGroup.label} has been successfuly added.`,
        })
        setIsAddActionOpen(false)
      },
      onError: (err) => {
        setAlert({
          type: 'error',
          message: 'There were 1 or more errors creating the action',
          list: listErrors(err.message),
        })
      },
    })
  }

  const handleUpdate = async (
    formInput: EditFormData,
    existingTemplate: YbActionScheduleTemplateRepresentation
  ) => {
    await patchCelebrationConfig({
      config: buildConfig(formInput),
      programId,
    })

    await updateActionScheduleTemplate({
      input: {
        actionScheduleTemplateUuid: existingTemplate.actionScheduleTemplateUuid,
        startOffset: buildStartOffset(formInput.startOffset, formInput.when),
        offsetFrom: formInput.offsetFrom,
        addInternationalShippingOffset:
          formInput.addInternationalShippingOffset,
        respectsPrivacy: false,
        endOffset: 0,
      },
      celebrationLevelGroupUuid,
      onSuccess: () => {
        setAlert({
          type: 'success',
          message: `Schedule has been successfuly updated.`,
        })
        setEditingTemplate(null)
      },
      onError: (err) => {
        setAlert({
          type: 'error',
          message: 'There were 1 or more errors updating the action.',
          list: listErrors(err.message),
        })
      },
    })
  }

  const handleDelete = async () => {
    await deleteActionScheduleTemplate({
      input: {
        actionScheduleTemplateUuids: [
          deleteActionState.actionScheduleTemplateUuid,
        ],
      },
      celebrationLevelGroupUuid,
      onSuccess: () => {
        setAlert({
          type: 'info',
          message: `${deleteActionState.name} has been successfuly deleted.`,
        })
        resetDeleteActionState()
      },
      onError: (err) => {
        setAlert({
          type: 'error',
          message: 'There were 1 or more errors deleting the action.',
          list: listErrors(err.message),
        })
        resetDeleteActionState()
      },
    })
  }

  const renderEditForm = () => {
    if (!editingTemplate) {
      return null
    }
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: `1px solid ${tannerGray[200]}`,
            paddingTop: '8px',
          }}
        >
          <Typography variant="h3">
            {editingTemplate.taskScheduleGroup.description}
          </Typography>
          <IconButton
            aria-label="menu button"
            sx={{ marginRight: '8px' }}
            onClick={() => setEditingTemplate(null)}
          >
            <Close />
          </IconButton>
        </Box>
        <ActionForm
          editingTemplate={editingTemplate}
          actionScheduleTemplates={actionScheduleTemplates}
          onEdit={handleUpdate}
          onCancel={() => setEditingTemplate(null)}
          error={updateAstError}
          loading={patchConfigLoading || updateAstLoading}
          celebrationFrequency={celebrationFrequency}
          batchDay={batchDay}
        />
      </>
    )
  }

  return (
    <>
      {displayHeading && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h3">Celebration timeline</Typography>
            {!isAddActionOpen ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setIsAddActionOpen(true)}
              >
                Add action
              </Button>
            ) : (
              <IconButton
                aria-label="menu button"
                sx={{ marginRight: '8px' }}
                onClick={() => setIsAddActionOpen(false)}
              >
                <Close />
              </IconButton>
            )}
          </Box>
          {alert && (
            <Box
              sx={{
                margin: '16px 0',
              }}
              ref={targetRef}
            >
              <Alert
                severity={alert.type}
                onClose={() => setAlert(null)}
                action={
                  alert.type === 'error' ? (
                    <Button
                      onClick={() => setIsErrorModalOpen(true)}
                      variant="text"
                      size="small"
                    >
                      View Errors
                    </Button>
                  ) : null
                }
              >
                {alert.message}
              </Alert>
            </Box>
          )}
          <Collapse in={isAddActionOpen}>
            <Box>
              <ActionForm
                actionScheduleTemplates={actionScheduleTemplates}
                onSave={(input) => handleSubmit(input)}
                onCancel={() => setIsAddActionOpen(false)}
                error={createAstError}
                loading={patchConfigLoading || createAstLoading}
                celebrationFrequency={celebrationFrequency}
                batchDay={batchDay}
              />
            </Box>
          </Collapse>
        </>
      )}

      {actionScheduleTemplates?.length > 0 && (
        <CelebrationGroupSchedule
          loading={getAstLoading}
          actionScheduleTemplates={actionScheduleTemplates}
          showToggleScheduleBtn={showToggleScheduleBtn}
          celebrationLevelGroupUuid={celebrationLevelGroupUuid}
          onDeleteActionTemplate={({ actionScheduleTemplateUuid, astName }) => {
            setDeleteActionState({
              isOpen: true,
              name: astName,
              actionScheduleTemplateUuid,
            })
          }}
          editForm={() => renderEditForm()}
          editingTemplate={editingTemplate}
          setEditingTemplate={setEditingTemplate}
        />
      )}
      <ConfirmationModal
        open={deleteActionState.isOpen}
        loading={deleteASTLoading}
        titleText="Delete action"
        destroy
        dataTestId="deleteActionConfirmation"
        messageText="This can't be undone. Are you sure?"
        primaryButtonText="Delete action"
        onModalClose={resetDeleteActionState}
        onModalAction={handleDelete}
      />
      <Modal
        open={isErrorModalOpen}
        onClose={() => setIsErrorModalOpen(false)}
        id="error-modal"
        title="Errors"
        actions={[
          <Button
            key="close"
            onClick={() => setIsErrorModalOpen(false)}
            variant="contained"
            color="primary"
          >
            Close
          </Button>,
        ]}
      >
        {alert && (
          <List>
            {alert.list?.map((error) => (
              <ListItem key={error}>
                <ListItemText>{error}</ListItemText>
              </ListItem>
            ))}
          </List>
        )}
      </Modal>
    </>
  )
}

export default CelebrationGroupActionScheduleContainer
