import { styled } from '@mui/material/styles'

export const Root = styled('section')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
}))

export const Form = styled('form')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(1),
  maxWidth: 350,
}))
