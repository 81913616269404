import React from 'react'
import { Button, Modal, Typography } from '@octanner/prism-core'

export interface ConfirmationModalProps {
  open: boolean
  destroy?: boolean
  titleText: string
  dataTestId: string
  messageText: string
  primaryButtonText: string
  secondaryButtonText?: string
  loading?: boolean
  onModalClose: () => void
  onModalAction: () => void
}

const ConfirmationModal = ({
  open,
  titleText,
  destroy = false,
  dataTestId,
  messageText,
  primaryButtonText,
  secondaryButtonText = 'Cancel',
  loading,
  onModalClose,
  onModalAction,
}: ConfirmationModalProps) => {
  return (
    <Modal
      actions={
        <>
          <Button
            disabled={loading}
            onClick={onModalClose}
            color="secondary"
            data-testid="yps:confirmation-modal:secondary"
          >
            {secondaryButtonText}
          </Button>
          <Button
            loading={loading}
            onClick={onModalAction}
            color={destroy ? 'error' : 'primary'}
            data-testid="yps:confirmation-modal:primary"
          >
            {primaryButtonText}
          </Button>
        </>
      }
      data-testid={dataTestId}
      id={dataTestId}
      onClose={onModalClose}
      open={open}
      title={titleText}
    >
      <Typography
        variant="body1"
        sx={{ textAlign: 'center', paddingTop: '16px', paddingBottom: '32px' }}
      >
        {messageText}
      </Typography>
    </Modal>
  )
}

export default ConfirmationModal
