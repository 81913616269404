/* eslint-disable react/prop-types */
import { Typography } from '@octanner/prism-core'
import { styled } from '@mui/material/styles'
import React, { useMemo } from 'react'
import colors from '../colors'
import { useGroupSettingsDispatch } from '../../contexts/GroupSettingsContext'

type DataTestIdFactory = (value: string) => string
type DisabledFactory = (value: string) => boolean

export interface Option {
  value: string
  displayText: string
}

interface BaseProps {
  options: string[]
  columns: number
  className?: string
  specialOptions?: Option[]
  getDataTestId?: DataTestIdFactory
  isDisabled?: DisabledFactory
}

interface SingleSelectBase extends BaseProps {
  value: string
  multiSelect?: false
}

interface SingleRequiredSelect extends SingleSelectBase {
  onChange: (value: string) => void
  required: true
  dispatch?: { type: string }
}

interface SingleNotRequiredSelect extends SingleSelectBase {
  onChange: (value?: string) => void
  required?: false
  dispatch?: { type: string }
}

type SingleSelect = SingleRequiredSelect | SingleNotRequiredSelect

interface MultiSelect extends BaseProps {
  value: string[]
  onChange?: (values: string[]) => void
  multiSelect: true
  dispatch?: { type: string }
}

export type GridSelectProps = SingleSelect | MultiSelect

interface Model {
  value: string
  displayText?: string
  selected: boolean
  disabled?: boolean
}

interface CheckboxProps {
  id: string
  className?: string
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

const GridContainer = styled('div')`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(3)};
`
const SpecialContainer = styled('div')`
  display: flex;
  grid-gap: ${({ theme }) => theme.spacing(3)};
`
const Label = styled('label')`
  padding: 4px;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  cursor: pointer;
`
const StyledCheckbox = styled('input')`
  display: none;
`

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => <StyledCheckbox type="checkbox" ref={ref} {...props} />
)
Checkbox.displayName = 'Checkbox'

type GridItemProps = Model & {
  onChange: (value: string, selected: boolean) => void
  freeSize?: boolean
  getDataTestId?: DataTestIdFactory
}

export const GridItem = ({
  value,
  selected,
  onChange,
  displayText,
  freeSize,
  getDataTestId,
  disabled,
}: GridItemProps) => {
  const text = displayText ?? value
  const labelStyle = {
    ...(selected ? { backgroundColor: colors.blue } : {}),
    ...(disabled ? { cursor: 'default' } : {}),
    ...(freeSize
      ? {
          height: 'inherit',
          width: 'inherit',
          paddingLeft: 8,
          paddingRight: 8,
        }
      : {}),
  }
  const textStyle = {
    ...(selected ? { color: colors.white } : {}),
    ...(disabled ? { opacity: '0.3' } : {}),
  }

  return (
    <Label htmlFor={value} style={labelStyle}>
      <Checkbox
        id={value}
        checked={selected}
        onChange={(e) => onChange(value, e.target.checked)}
        data-testid={getDataTestId && getDataTestId(value)}
        disabled={disabled}
      />
      <Typography style={textStyle}>{text}</Typography>
    </Label>
  )
}

const isMultiselect = (object: object): object is MultiSelect =>
  'multiSelect' in object

const GridSelect = React.forwardRef<HTMLDivElement, GridSelectProps>(
  (props, ref) => {
    const {
      options,
      columns,
      className,
      specialOptions,
      getDataTestId,
      isDisabled,
    } = props
    const dispatch = useGroupSettingsDispatch()

    const values = useMemo(
      () => (isMultiselect(props) ? props.value : [props.value]),
      [props]
    )

    const models: Model[] = useMemo(
      () =>
        options.map((option) => ({
          value: option,
          selected: values?.includes(option),
          disabled: isDisabled && isDisabled(option),
        })),
      [values, options, isDisabled]
    )

    const specialModels = useMemo(
      () =>
        (specialOptions || []).map((option) => ({
          ...option,
          selected: values?.includes(option.value),
        })),
      [values, specialOptions]
    )

    const handleChange = (value: string, selected: boolean) => {
      if (isMultiselect(props)) {
        props.onChange(
          selected
            ? [...props.value, value]
            : props.value.filter((v) => v !== value)
        )
        return
      }
      if (props.required && !selected) return
      props.onChange(selected ? value : undefined)
    }

    return (
      <div className={className} ref={ref}>
        <GridContainer
          data-testid="yps:grid-select"
          style={{ gridTemplateColumns: `repeat(${columns}, 1fr [col-start])` }}
        >
          {models.map((model) => (
            <GridItem
              key={model.value}
              onChange={
                props?.dispatch?.type === 'setSelectedYears'
                  ? () =>
                      dispatch({ type: 'setSelectedYears', year: model.value })
                  : handleChange
              }
              getDataTestId={getDataTestId}
              {...model}
            />
          ))}
        </GridContainer>
        <SpecialContainer>
          {specialModels.map((model) => (
            <GridItem
              key={model.value}
              onChange={handleChange}
              getDataTestId={getDataTestId}
              freeSize
              {...model}
            />
          ))}
        </SpecialContainer>
      </div>
    )
  }
)
GridSelect.displayName = 'GridSelect'

export default GridSelect

export const buildOptions = (min: number, max: number): string[] =>
  new Array(max - min + 1).fill(0).map((_, index) => (index + min).toString())
