import { Typography } from '@octanner/prism-core'
import React from 'react'
import { useGroupSettings } from '../../contexts/GroupSettingsContext'
import { YbActionScheduleTemplateRepresentation } from '../../models/ActionScheduleTemplate'

interface Props {
  template: YbActionScheduleTemplateRepresentation
}

export default function TimelineItemFooter({ template }: Props) {
  const groupSettingsContext = useGroupSettings()
  if (template.taskScheduleGroup.code === 'REQUEST_CELEBRANT_FEEDBACK') {
    return <Typography>{groupSettingsContext?.surveyUrl}</Typography>
  }

  return null
}
