import { gql } from '@apollo/client'
import {
  IDENTITY,
  YB_ACRUAL_POINTS_REGION_FRAGMENT,
  YB_CELEBRATION_GROUP_CONFIG,
  YB_PROGRAM_BASE_FRAGMENT,
  YB_PROGRAM_OFFSETS_FRAGMENT,
} from './fragments'

// queries --------------------------------------------------

export const GET_ACTION_SCHEDULE_TEMPLATES_BY_CLG_ID = gql`
  query YbGetActionScheduleTemplatesByCelebrationLevelGroupId(
    $input: YbGetActionScheduleTemplatesByCelebrationLevelGroupUuidInput!
  ) {
    ybGetActionScheduleTemplatesByCelebrationLevelGroupId(input: $input) {
      actionScheduleTemplates {
        actionScheduleTemplateUuid
        taskScheduleGroup {
          id
          code
          description
          scheduleGroupCategory
          numReminders
        }
        startOffset
        endOffset
        offsetFrom
        addInternationalShippingOffset
        respectsPrivacy
        celebrationLevelGroup {
          id
        }
      }
    }
  }
`

export const GET_BUDGET_GROUP_REGIONS = gql`
  ${YB_ACRUAL_POINTS_REGION_FRAGMENT}
  query getBudgetGroupRegions($input: YbProgramOffsetsByProgramIdInput!) {
    ybProgramOffsetsByProgramId(input: $input) {
      program {
        accrualPointsRegions {
          ...YbAccrualPointsRegion
        }
      }
    }
  }
`

export const GET_PROGRAM_OFFSETS_BY_PROGRAM_ID = gql`
  ${YB_PROGRAM_OFFSETS_FRAGMENT}
  query ybProgramOffsetsByProgramId($programId: UUID!) {
    ybProgramOffsetsByProgramId(input: { programId: $programId }) {
      ...YbProgramOffsets
    }
  }
`

export const IDENTITY_SEARCH = gql`
  ${IDENTITY}
  query IdentitySearch($identityCondition: IdentityInput!) {
    identitySearch(condition: $identityCondition) {
      identities {
        ...Identity
        identityUuid
        uniqueId
        profile {
          avatar
        }
      }
    }
  }
`

export const GET_CELEBRATION_LEVELS = gql`
  query YbProgramConfigs($input: YbProgramQueryInput!) {
    ybProgramConfigs(input: $input) {
      pageConnection {
        nodes {
          id
          celebrationLevelGroupConfigs {
            celebrationLevelConfigs {
              rank
            }
          }
        }
      }
    }
  }
`

export const GET_VALID_OFFSET_FROM_VALUES = gql`
  query ybGetValidOffsetFromValues($input: YbGetValidOffsetFromValuesInput!) {
    ybGetValidOffsetFromValues(input: $input) {
      validOffsetsFrom
    }
  }
`

// mutations --------------------------------------------------

const patchInputs = {
  main: '$programConfigInput: YbProgramInput!',
  add: '$addLocalesInput: YbProgramYearbookLocalesInput!',
  remove: '$removeLocalesInput: YbProgramYearbookLocalesInput!',
}

const patchMutations = {
  main: `ybPatchProgramConfigs(input: $programConfigInput) { ...YbProgramBaseFragment }`,
  add: `ybAddProgramYearbookLocales(input: $addLocalesInput) { id }`,
  remove: `ybRemoveProgramYearbookLocales(input: $removeLocalesInput) { yearbookLocales }`,
}

export const buildPatchProgramConfigMutation = (
  isNew: boolean,
  options = { add: false, remove: false }
) => {
  const { add, remove } = options
  const mutations = (
    isNew
      ? [
          patchMutations.main,
          add && patchMutations.add,
          remove && patchMutations.remove,
        ]
      : [
          add && patchMutations.add,
          remove && patchMutations.remove,
          patchMutations.main,
        ]
  ).filter(Boolean)
  const inputs = [
    patchInputs.main,
    add && patchInputs.add,
    remove && patchInputs.remove,
  ].filter(Boolean)

  return gql`
    mutation patchProgramConfig(
      ${inputs.join(' ')}
    ) {
      ${mutations.join(' ')}
    }
    ${YB_PROGRAM_BASE_FRAGMENT}
  `
}

export const PATCH_CELEBRATION_CONFIG = gql`
  ${YB_CELEBRATION_GROUP_CONFIG}
  mutation patchConfig($input: YbCelebrationLevelGroupConfigUpdateInput!) {
    ybPatchCelebrationLevelGroupConfigs(input: $input) {
      ...YbCelebrationGroupConfig
    }
  }
`

export const YB_CREATE_ACCRUAL_POINTS_CONFIG = gql`
  ${YB_ACRUAL_POINTS_REGION_FRAGMENT}
  mutation ybCreateAccrualPointsConfig($input: YbCreateAccrualPointsConfig!) {
    ybCreateAccrualPointsConfig(input: $input) {
      ...YbAccrualPointsRegion
    }
  }
`
export const YB_UPDATE_ACCRUAL_POINTS_CONFIG = gql`
  ${YB_ACRUAL_POINTS_REGION_FRAGMENT}
  mutation ybUpdateAccrualPointsConfig($input: YbUpdateAccrualPointsConfig) {
    ybUpdateAccrualPointsConfig(input: $input) {
      ...YbAccrualPointsRegion
    }
  }
`

export const YB_DELETE_ACCRUAL_POINTS_REGION = gql`
  mutation ybDeleteAccrualPointsConfig($input: YbDeleteAccrualPointsConfig!) {
    ybDeleteAccrualPointsConfig(input: $input) {
      id
    }
  }
`

export const PATCH_OFFSETS = gql`
  ${YB_PROGRAM_OFFSETS_FRAGMENT}
  mutation patchOffsets($input: YbProgramOffsetsInput!) {
    ybPatchProgramOffsets(input: $input) {
      ...YbProgramOffsets
    }
  }
`
export const CREATE_DEFAULT_PROGRAM = gql`
  ${YB_PROGRAM_BASE_FRAGMENT}
  mutation createDefaultProgram($input: YbCreateDefaultProgramInput!) {
    ybCreateDefaultProgram(input: $input) {
      ...YbProgramBaseFragment
    }
  }
`

export const UPDATE_CORE_AWARD_LEVELS = gql`
  mutation UpdateCoreAwardLevels($input: UpdateCoreAwardLevelsRequest!) {
    updateCoreAwardLevels(input: $input) {
      awardLevels {
        nodes {
          awardLevelName
          minPoints
          maxPoints
        }
      }
    }
  }
`
export const CREATE_ACTION_SCHEDULE_TEMPLATE = gql`
  mutation YbCreateActionScheduleTemplate(
    $input: YbCreateActionScheduleTemplateInput!
  ) {
    ybCreateActionScheduleTemplate(input: $input) {
      actionScheduleTemplateUuid
      addInternationalShippingOffset
      endOffset
      offsetFrom
      respectsPrivacy
      startOffset
    }
  }
`

export const UPDATE_ACTION_SCHEDULE_TEMPLATE = gql`
  mutation YbUpdateActionScheduleTemplate(
    $input: YbUpdateActionScheduleTemplateInput!
  ) {
    ybUpdateActionScheduleTemplate(input: $input) {
      actionScheduleTemplateUuid
      addInternationalShippingOffset
      endOffset
      offsetFrom
      respectsPrivacy
      startOffset
    }
  }
`

export const DELETE_ACTION_SCHEDULE_TEMPLATE = gql`
  mutation YbDeleteActionScheduleTemplate(
    $input: YbDeleteActionScheduleTemplateInput!
  ) {
    ybDeleteActionScheduleTemplate(input: $input) {
      actionScheduleTemplateUuid
    }
  }
`
export const GET_ALL_TASK_SCHEDULE_GROUPS = gql`
  query YbGetAllTaskScheduleGroups {
    ybGetAllTaskScheduleGroups {
      taskScheduleGroups {
        id
        code
        description
        numReminders
        scheduleGroupCategory
      }
    }
  }
`

export const CREATE_NEW_CELEBRATION = gql`
  mutation YbCreateCelebrationWithoutEligibility(
    $input: [YbCreateCelebrationWithoutEligibilityInput!]!
  ) {
    ybCreateCelebrationWithoutEligibility(input: $input) {
      results {
        __typename
        ... on YbCreateCelebrationError {
          identityUuid
          message
        }
        ... on YbCelebrationRepresentation {
          status
        }
      }
    }
  }
`
