import React from 'react'
import { Box, Tag } from '@octanner/prism-core'
import { DetailViewModel } from '../../models/CelebrationCard'

type Props = {
  details: DetailViewModel[]
  milestone: boolean
}

const CelebrationTagContainer = ({ details, milestone }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 4,
        marginTop: 3,
      }}
    >
      <Tag
        variant={milestone ? 'success' : 'info'}
        data-testid="milestone-type"
      >
        {!milestone ? 'Non-milestone' : 'Milestone'}
      </Tag>
      {details.map((detail, index) => {
        if (!detail || !detail.checked) return null

        return (
          <Tag
            key={`${index}-${detail.text}`}
            variant="archived"
            data-testid={`celebration-group:${detail.text}`}
          >
            {detail.text}
          </Tag>
        )
      })}
    </Box>
  )
}

export default CelebrationTagContainer
