import { Typography } from '@octanner/prism-core'
import React, { ReactNode } from 'react'

type Props = {
  title: ReactNode
  subtitle?: ReactNode
}

export const FormFieldHelper = ({ title, subtitle }: Props) => (
  <>
    <Typography variant="h3" sx={{ pt: 4 }}>
      {title}
    </Typography>
    {subtitle && <Typography>{subtitle}</Typography>}
  </>
)
