import { BaseAccrualPointsConfig } from '../common/models/YbUpdateAccrualPointsConfig'
import React, { useMemo } from 'react'
import useCreateAccrualPointsConfig from '../hooks/useCreateAccrualPointsConfig'
import useDeleteAccrualPointsConfig from '../hooks/useDeleteAccrualPointsConfig'
import useGetBudgetGroup from '../hooks/useGetBudgetGroup'
import useGetBudgetGroupCountries from '../hooks/useGetBudgetGroupCountries'
import useGetBudgetGroupRegions from '../hooks/useGetBudgetGroupRegions'
import useUpdateAccrualPointsConfig from '../hooks/useUpdateAccrualPointsConfig'
import { YearbookProgramSettingsChangeEvent } from '../models/YearbookProgramSettingsChangeEvent'
import BudgetGroupForm from './BudgetGroupForm'

interface Props {
  onChange: (event: YearbookProgramSettingsChangeEvent) => void
  programId: string
  budgetGroupId?: string
}

const BudgetGroupFormContainer: React.FC<Props> = ({
  programId,
  budgetGroupId,
  onChange,
}) => {
  const countries = useGetBudgetGroupCountries(programId)
  const budgetGroup = useGetBudgetGroup(programId, budgetGroupId)
  const regions = useGetBudgetGroupRegions(programId)
  const defaultRegion = regions?.find((r) => r.defaultRegion)
  const [deleteConfig] = useDeleteAccrualPointsConfig()
  const [createConfig] = useCreateAccrualPointsConfig()
  const [updateConfig] = useUpdateAccrualPointsConfig()

  const levels = useMemo(
    () =>
      defaultRegion
        ? defaultRegion.values.map((pv) => pv.celebrationLevel)
        : [],
    [defaultRegion]
  )

  const handleOnDelete = async () => {
    if (!budgetGroup || budgetGroup.defaultRegion) {
      return
    }
    await deleteConfig(budgetGroup.id, programId)
    onChange('delete')
  }

  const handleSubmit = async (baseConfig: BaseAccrualPointsConfig) => {
    if (budgetGroup) {
      await updateConfig(programId, baseConfig, budgetGroupId)
      onChange('update')
    } else {
      await createConfig(programId, baseConfig)
      onChange('create')
    }
  }

  if (!countries.length || !defaultRegion || (budgetGroupId && !budgetGroup))
    return null

  return (
    <BudgetGroupForm
      defaultValue={100}
      countries={countries}
      budgetGroup={budgetGroup}
      levels={levels}
      onSubmit={handleSubmit}
      onCancel={() => onChange('cancel')}
      onDelete={handleOnDelete}
    />
  )
}

export default BudgetGroupFormContainer
