import { useQuery } from '@apollo/client'
import { Response } from '../common/components/IdentitySelector'
import { IDENTITY_SEARCH } from '../graphql'

export const useIdentitySearch = ({
  customerId,
  name,
}: {
  customerId: string
  name: string
}) => {
  const { data, loading, error } = useQuery<Response>(IDENTITY_SEARCH, {
    variables: {
      identityCondition: {
        customerId: customerId,
        name: name,
      },
    },
    skip: name.trim().length < 1 || !customerId,
  })

  return { data, loading, error }
}
