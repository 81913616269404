import {
  Box,
  Checkbox,
  FormControlLabel,
  SelectionButton,
  SingleSelectionButtons,
} from '@octanner/prism-core'
import React from 'react'
import { Actions, ContextData } from '../models/GroupSettingsContext'

const yearbookSettingsObj = {
  leaderSettings: [
    {
      label: 'Leader personal notes',
      key: 'leaderNotesEnabled',
      dispatchKey: 'toggleLeaderNotes',
      childSettings: [
        {
          label: 'Leader photos',
          key: 'leaderPhotoEnabled',
          dispatchKey: 'toggleLeaderPhoto',
        },
        {
          label: 'Leader video',
          key: 'leaderVideoEnabled',
          dispatchKey: 'toggleLeaderVideo',
        },
      ],
    },
  ],
  peerSettings: [
    {
      label: 'Peer personal notes',
      key: 'peerNotesEnabled',
      dispatchKey: 'togglePeerNotes',
      childSettings: [
        {
          label: 'Peer photos',
          key: 'peerPhotoEnabled',
          dispatchKey: 'togglePeerPhoto',
        },
        {
          label: 'Peer video',
          key: 'peerVideoEnabled',
          dispatchKey: 'togglePeerVideo',
        },
      ],
    },
  ],
}

interface Props {
  groupSettingsContext: ContextData
  dispatch: (action: Actions) => void
}

const YearbookSettings = ({ groupSettingsContext, dispatch }: Props) => {
  return (
    <>
      <SingleSelectionButtons
        value={
          groupSettingsContext?.printYearbookEnabled ? 'printed' : 'digital'
        }
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          dispatch({ type: 'toggleDigitalorPrintYearbook' })
          if (!groupSettingsContext.printYearbookEnabled) {
            dispatch({ type: 'togglePrintCoverType' })
          }
        }}
      >
        <SelectionButton
          data-testid={'yearbook-settings:digitalOnly'}
          value="digital"
        >
          Digital only
        </SelectionButton>
        <SelectionButton
          data-testid={'yearbook-settings:printAndDigital'}
          value="printed"
        >
          Print and digital
        </SelectionButton>
      </SingleSelectionButtons>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
        }}
      >
        {Object.entries(yearbookSettingsObj).map(([category, settings]) => (
          <Box
            key={category}
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {settings.map((setting) => (
              <React.Fragment key={setting.key}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={groupSettingsContext[setting.key]}
                      name={setting.key}
                      tabIndex={-1}
                      data-testid={setting.key}
                    />
                  }
                  label={setting.label}
                  tabIndex={0}
                  onChange={() =>
                    // @ts-ignore - using correct boolean toggles
                    dispatch({ type: setting.dispatchKey })
                  }
                />
                {groupSettingsContext[setting.key] && setting.childSettings && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {setting.childSettings.map((childSetting) => (
                      <FormControlLabel
                        key={childSetting.key}
                        control={
                          <Checkbox
                            checked={groupSettingsContext[childSetting.key]}
                            name={childSetting.key}
                            tabIndex={-1}
                            data-testid={childSetting.key}
                          />
                        }
                        label={childSetting.label}
                        tabIndex={0}
                        onChange={() =>
                          // @ts-ignore - using correct boolean toggles
                          dispatch({ type: childSetting.dispatchKey })
                        }
                      />
                    ))}
                  </Box>
                )}
              </React.Fragment>
            ))}
          </Box>
        ))}
      </Box>
    </>
  )
}

export default YearbookSettings
