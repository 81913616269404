import { Box, Typography } from '@octanner/prism-core'
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors'
import React from 'react'

interface Props {
  messages: string[]
  useBullets?: boolean
}

const ActionFormMessages = ({ messages, useBullets = false }: Props) => {
  return (
    <Box
      data-testid="action-form:messages"
      sx={{
        backgroundColor: tannerGray[100],
        padding: '8px',
        width: '70%',
        borderRadius: '4px',
      }}
    >
      {useBullets ? (
        <ul>
          {messages.map((message, index) => (
            <li key={index}>
              <Typography variant="body1">{message}</Typography>
            </li>
          ))}
        </ul>
      ) : (
        messages.map((message, index) => (
          <Typography key={index} variant="body1">
            {message}
          </Typography>
        ))
      )}
    </Box>
  )
}

export default ActionFormMessages
