import { styled } from '@mui/material/styles'
import CircularProgress from '@octanner/prism-core/CircularProgress'
import React from 'react'

const LoadingWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '25vh',
})

const Loading = () => (
  <LoadingWrapper data-testid="loading-element">
    <CircularProgress />
  </LoadingWrapper>
)

export default Loading
