import { YbCelebrationLevelGroupConfigInput } from '../common/models/YbCelebrationLevelGroupConfigInput'
import { GET_PROGRAM_OFFSETS_BY_PROGRAM_ID } from '../graphql'
import { YearbookProgramSettingsChangeEvent } from '../models/YearbookProgramSettingsChangeEvent'
import { createLevelGroupConfigInput } from '../utilities/createLevelGroupConfigInput'
import React, { useMemo, useState } from 'react'
import { GroupSettingsContextProvider } from '../contexts/GroupSettingsContext'
import { useGetProgramOffsetsByProgramId } from '../hooks/useGetProgramOffsetsByProgramId'
import usePatchCelebrationConfig from '../hooks/usePatchCelebrationConfig'
import StylesProvider from '../utilities/StylesProvider'
import CelebrationGroupEdit from './CelebrationGroupEdit'

interface Props {
  programId: string
  celebrationGroupId?: string
  launchDarklyFlags?: { key: string; value: boolean }[]
  onChange: (event: YearbookProgramSettingsChangeEvent) => void
}

const CelebrationGroupSettings = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [patchConfig, errors] = usePatchCelebrationConfig()
  const { data } = useGetProgramOffsetsByProgramId(props.programId)

  const showCelebrationLevelGroupSection = props?.launchDarklyFlags?.some(
    (flag) => flag.key === 'ccAdminCcybClgSettingsD241017' && flag.value
  )

  const noRecogId = errors?.error
    ?.toString()
    ?.includes("doesn't mean you SHOULD")

  const handleSubmit = async (config: YbCelebrationLevelGroupConfigInput) => {
    setLoading(true)
    await patchConfig({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_PROGRAM_OFFSETS_BY_PROGRAM_ID,
          variables: { programId: props.programId },
          errorPolicy: 'all',
        },
      ],
      variables: {
        input: {
          config: {
            ...config,
            ...(celebrationGroup?.id
              ? {
                  id: celebrationGroup.id,
                  celebrationLevelConfigs: createLevelGroupConfigInput(
                    config,
                    celebrationGroup?.celebrationLevelConfigs
                  ),
                }
              : {}),
          },
          programId: props.programId,
        },
      },
    })
    props.onChange('update')
  }

  const celebrationGroup = useMemo(
    () =>
      data?.program?.celebrationLevelGroupConfigs.find(
        (c) => c.id === props.celebrationGroupId
      ),
    [data?.program?.celebrationLevelGroupConfigs, props.celebrationGroupId]
  )

  const usedYears = useMemo(() => {
    if (
      !data?.program?.celebrationLevelGroupConfigs ||
      (!celebrationGroup && props.celebrationGroupId)
    ) {
      return []
    }

    return data.program.celebrationLevelGroupConfigs
      .filter((clgc) => clgc.id !== celebrationGroup?.id)
      .map((clgc) => clgc.celebrationLevelConfigs)
      .reduce((agg, current) => [...agg, ...current], [])
      .map((clc) => clc.rank.toString())
  }, [
    data?.program?.celebrationLevelGroupConfigs,
    celebrationGroup,
    props.celebrationGroupId,
  ])

  if (!data) {
    return null
  }

  return (
    <StylesProvider>
      <GroupSettingsContextProvider
        celebrationGroup={celebrationGroup}
        usedYears={usedYears}
      >
        <CelebrationGroupEdit
          program={data.program}
          onSubmit={handleSubmit}
          onCancel={() => props.onChange('cancel')}
          noRecogId={noRecogId}
          loading={loading}
          showLaunchDarkly={showCelebrationLevelGroupSection}
        />
      </GroupSettingsContextProvider>
    </StylesProvider>
  )
}

export default CelebrationGroupSettings
