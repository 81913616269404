import React, { useState } from 'react'
import { Box, IconButton, Menu, MenuItem } from '@octanner/prism-core'
import { MenuDots } from '@octanner/prism-icons'

type MenuButtonProps = {
  onEditClick?: () => void
  onDeleteClick?: () => void
}

const MenuButton = ({ onEditClick, onDeleteClick }: MenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event: React.BaseSyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuDots />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {onEditClick && <MenuItem onClick={onEditClick}>Edit</MenuItem>}
        {onDeleteClick && <MenuItem onClick={onDeleteClick}>Delete</MenuItem>}
      </Menu>
    </Box>
  )
}

export default MenuButton
