import React, { useState, useEffect } from 'react'
import { Alert, Box, FormControl, FormGroup } from '@octanner/prism-core'
import { useForm } from 'react-hook-form'
import {
  YbActionScheduleTemplateRepresentation,
  YbCreateActionScheduleTemplateInput,
  YbUpdateActionScheduleTemplateInput,
} from 'library/src/models/ActionScheduleTemplate'
import { EventOption, eventOptions } from './utils'
import ActionFormMessages from './ActionFormMessage'
import ShippingInputs from './ShippingInputs'
import SurveyUrlInput from './SurveyUrlInput'
import ActionButtons from './ActionButtons'
import ScheduleInputs from './ScheduleInputs'
import ActionInput from './ActionInput'
import { ApolloError } from '@apollo/client'
import { useGroupSettings } from 'library/src/contexts/GroupSettingsContext'

type CommonActionScheduleFormFields = {
  when: 'before' | 'after'
  surveyUrl: string
  shippingAddress: string
  attentionTo: string
  billingLocation: string
}

export type FormData = Omit<
  YbCreateActionScheduleTemplateInput,
  'taskScheduleGroup'
> &
  CommonActionScheduleFormFields & {
    taskScheduleGroup: EventOption
  }

export type EditFormData = Pick<
  YbUpdateActionScheduleTemplateInput,
  'offsetFrom' | 'startOffset' | 'addInternationalShippingOffset'
> &
  CommonActionScheduleFormFields

interface Props {
  actionScheduleTemplates: YbActionScheduleTemplateRepresentation[]
  editingTemplate?: YbActionScheduleTemplateRepresentation | null
  error: ApolloError
  loading: boolean
  onSave?: (data: FormData) => void
  onEdit?: (
    data: EditFormData,
    existingTemplate: YbActionScheduleTemplateRepresentation
  ) => void
  onCancel: () => void
}

const ActionForm = ({
  actionScheduleTemplates,
  editingTemplate,
  error,
  loading,
  onSave,
  onEdit,
  onCancel,
}: Props) => {
  const [lockInputs, setLockInputs] = useState(false)
  const groupSettingsContext = useGroupSettings()
  const [formAlert, setFormAlert] = useState({
    isOpen: !!error,
    type: error ? 'error' : 'success',
    message: '',
  })
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      taskScheduleGroup: editingTemplate
        ? {
            id: editingTemplate.taskScheduleGroup.id,
            label: editingTemplate.taskScheduleGroup.description,
            value: editingTemplate.taskScheduleGroup.code,
          }
        : {
            id: 0,
            label: '',
            value: '',
          },
      startOffset: editingTemplate ? Math.abs(editingTemplate.startOffset) : 0,
      when:
        editingTemplate && editingTemplate.startOffset > 0 ? 'after' : 'before',
      offsetFrom: editingTemplate
        ? editingTemplate.offsetFrom
        : 'CELEBRATION_DATE',
      addInternationalShippingOffset: false,
      shippingAddress: '',
      attentionTo: '',
      billingLocation: '',
      surveyUrl:
        editingTemplate?.taskScheduleGroup.code === 'REQUEST_CELEBRANT_FEEDBACK'
          ? groupSettingsContext.surveyUrl
          : '',
    },
  })

  const actionValue: EventOption = watch('taskScheduleGroup')

  const onSubmit = handleSubmit(
    (data) => {
      if (editingTemplate && onEdit) {
        onEdit(data, editingTemplate)
      } else {
        onSave(data)
        reset()
      }
    },
    () => {
      setFormAlert({
        isOpen: true,
        type: 'error',
        message: 'There was an error in the form.',
      })
    }
  )

  const handleCancel = (e) => {
    e.preventDefault()
    reset()
    setFormAlert({ isOpen: false, type: '', message: '' })
    onCancel()
  }

  eventOptions.sort((a, b) => {
    const aFirstWord = a.label.split(' ')[0]
    const bFirstWord = b.label.split(' ')[0]
    return aFirstWord.localeCompare(bFirstWord)
  })
  const filteredEventOptions = eventOptions.filter(
    (event) =>
      !actionScheduleTemplates.some(
        (template) => template.taskScheduleGroup.code === event.value
      )
  )

  const actionsAffectedByPrivacySettings = [
    'REGISTER_INVITES',
    'REQUEST_MANAGER_PERSONAL_NOTE',
    'NOTIFY_PEERS_ANNIVERSARY_DAY',
    'REQUEST_PEERS_PERSONAL_NOTE',
  ]
  const shippingInputActions = [
    'AUTOSHIP_AWARD_ORDER',
    'REGISTER_INVITES',
    'REQUEST_MANAGER_PERSONAL_NOTE',
  ]

  const reminderInfoMessage = [
    'NOTIFY_CELEBRANT_INVITE_PEERS',
    'NOTIFY_CELEBRANT_ORDER_FROM_CATALOG',
  ]

  useEffect(() => {
    if (
      actionValue?.value === 'DEPOSIT_POINTS' ||
      actionValue?.value === 'DEPOSIT_ACCRUAL_POINTS'
    ) {
      setValue('when', 'before')
      setValue('offsetFrom', 'CELEBRATION_DATE')
      setValue('startOffset', 1)
      setLockInputs(true)
    } else {
      setLockInputs(false)
    }
    if (actionValue?.value === 'AUTOSHIP_AWARD_ORDER') {
      setValue('addInternationalShippingOffset', true)
      setValue('shippingAddress', 'WORK_1')
      setValue('attentionTo', 'MANAGER')
      setValue('billingLocation', 'BILLING_LOCATION_1')
    }
  }, [actionValue, setValue])

  return (
    <FormControl
      data-testid="action-form"
      sx={{ m: 3, width: '100%' }}
      component="fieldset"
      variant="standard"
    >
      <FormGroup
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        {formAlert.isOpen && (
          <Box
            sx={{
              width: '70%',
            }}
          >
            <Alert
              data-testid="action-form:error-alert"
              onClose={() =>
                setFormAlert({ isOpen: false, type: '', message: '' })
              }
              severity="error"
            >
              {formAlert.message || 'An error has occured. Please try again.'}
            </Alert>
          </Box>
        )}

        {!editingTemplate && (
          <ActionInput
            filteredEventOptions={filteredEventOptions}
            control={control}
            errors={errors}
          />
        )}

        {reminderInfoMessage.includes(actionValue?.value as string) && (
          <ActionFormMessages
            useBullets
            messages={[
              'Includes three reminder emails sent every seven days after the initial notification.',
              'Action is removed when privacy settings are turned on.',
            ]}
          />
        )}
        {actionsAffectedByPrivacySettings.includes(
          actionValue?.value as string
        ) && (
          <ActionFormMessages
            messages={[
              'Action is removed when privacy settings are turned on.',
            ]}
          />
        )}

        <ScheduleInputs
          lockInputs={lockInputs}
          control={control}
          errors={errors}
        />

        {shippingInputActions.includes(actionValue?.value as string) && (
          <ShippingInputs control={control} actionValue={actionValue} />
        )}

        {(actionValue?.value === 'REQUEST_CELEBRANT_FEEDBACK' ||
          editingTemplate?.taskScheduleGroup.code ===
            'REQUEST_CELEBRANT_FEEDBACK') && (
          <SurveyUrlInput control={control} errors={errors} />
        )}
        <ActionButtons
          isEditMode={!!editingTemplate}
          onSubmit={onSubmit}
          onCancel={handleCancel}
          loading={loading}
        />
      </FormGroup>
    </FormControl>
  )
}

export default ActionForm
