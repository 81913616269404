import { useQuery } from '@apollo/client'
import { YbProgramOffsets } from '../common/models/ProgramOffsets'
import { GET_PROGRAM_OFFSETS_BY_PROGRAM_ID } from '../graphql'

type Result = {
  data: YbProgramOffsets | undefined
  startPolling: (interval: number) => void
  stopPolling: () => void
}

export function useGetProgramOffsetsByProgramId(id: string): Result {
  const { data, startPolling, stopPolling } = useQuery<{
    ybProgramOffsetsByProgramId: YbProgramOffsets
  }>(GET_PROGRAM_OFFSETS_BY_PROGRAM_ID, {
    variables: { programId: id },
    errorPolicy: 'all',
  })
  return { data: data?.ybProgramOffsetsByProgramId, startPolling, stopPolling }
}
