import { Select, MenuItem } from '@octanner/prism-core'
import React from 'react'
import { useGetProgramOffsetsByProgramId } from '../hooks/useGetProgramOffsetsByProgramId'
import { useGetCelebrationLevels } from '../hooks/useGetCelebrationLevels'

type Props = {
  onChange: (val: number) => void
  value: number | undefined
  programId: string
  error: boolean
  helperText?: string
}

const CelebrationLevelSelector: React.FC<Props> = ({
  programId,
  value,
  onChange,
  error,
}) => {
  const { data } = useGetProgramOffsetsByProgramId(programId)

  const customerUuid = data?.program?.coreProgram?.customer?.id ?? ''

  const { data: celebrationLevels = [] } = useGetCelebrationLevels({
    programId,
    customerUuid,
  })
  return (
    <Select
      value={value}
      onChange={(e) => {
        onChange(Number(e.target.value))
      }}
      sx={{ width: '400px' }}
      label="Anniversary Year"
      error={error}
      helperText={error ? 'Required' : undefined}
      MenuProps={{
        style: {
          maxHeight: 400,
        },
      }}
    >
      {celebrationLevels?.map((level) => (
        <MenuItem key={level} value={level}>
          {level}
        </MenuItem>
      ))}
    </Select>
  )
}

export default CelebrationLevelSelector
