import React, { ComponentProps, useEffect } from 'react'
import Search from '@octanner/prism-core/Search'
import { useDebouncedValue } from '@octanner/prism-core/hooks'
import { useIdentitySearch } from '../../hooks/useIdentitySearch'
import {
  IdentityBase,
  IdentityWithErrors,
} from '../../models/CelebrationGroupEdit'

const isIdentityBase = (object?: object): object is IdentityBase =>
  object && 'id' in object
export const formatIdentityName = (identity: IdentityBase) =>
  `${identity.preferredName ?? ''} ${identity.lastName ?? ''}`.trim()

export interface Response {
  identitySearch: {
    identities: IdentityBase[]
  }
}

interface Props {
  'data-testid'?: string
  customerId: string
  onChange: (value?: IdentityBase) => void
  identity?: IdentityWithErrors
  renderOption?: ComponentProps<
    typeof Search<IdentityBase | undefined>
  >['renderOption']
  placeholder?: string
  error?: boolean
  helperText?: string
  onError?: (error: { message: string } | null) => void
}

const IdentitySelector = ({
  'data-testid': dataTestId,
  customerId,
  onChange,
  identity,
  placeholder,
  renderOption,
  error,
  helperText,
  onError,
}: Props) => {
  const [searchText, setSearchText] = React.useState('')
  const debouncedText = useDebouncedValue(searchText)
  const results = useIdentitySearch({ customerId, name: debouncedText })
  const options = React.useMemo(
    () =>
      results.data?.identitySearch.identities
        ? results.data?.identitySearch.identities.map((i) => ({
            ...i,
            disabled: false,
          }))
        : [],
    [results.data?.identitySearch.identities]
  )

  useEffect(() => {
    if (results.error && onError) {
      onError(results.error)
    }
  }, [onError, results.error])

  const getHelperText = () => {
    if (helperText) return helperText
    return identity?.errors?.length
      ? identity.errors[0]
      : 'Enter a name to search'
  }

  return (
    <Search<IdentityBase | undefined>
      id="people-search"
      inputDataTestId={dataTestId}
      // @ts-ignore this is a boolean prop
      multiple={false}
      error={error || Boolean(identity?.errors)}
      helperText={getHelperText()}
      loading={results.loading}
      // @ts-ignore bad typing from prism
      value={identity ?? ''}
      onChange={(_, selectedOptions) => {
        if (!isIdentityBase(selectedOptions)) return onChange()
        onChange(selectedOptions)
      }}
      onInputChange={(_, searchText) => setSearchText(searchText)}
      options={options}
      placeholder={placeholder}
      getOptionLabel={formatIdentityName}
      renderOption={renderOption}
    />
  )
}

export default IdentitySelector
