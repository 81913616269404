import { Button, TextField } from '@octanner/prism-core'
import { MainSettings } from '@octanner/yearbook-program-settings'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Root } from './Styled'
import { useFlags } from 'launchdarkly-react-client-sdk'

type Props = { programId?: string }

const ProgramSettingsWrapper: React.FC<Props> = ({ programId }) => {
  const history = useHistory()
  const [form, setForm] = useState(programId)

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (!form) return
    history.push(`/program-settings/${form}`)
  }

  const handleEditGroupClick = (groupId?: string) => {
    groupId
      ? history.push(`/celebration-settings/${programId}/${groupId}`)
      : history.push(`/celebration-settings/new/${programId}`)
  }

  const onEditAccrualPointRegion = (regionId: string) => {
    history.push(`/budget-settings/${programId}/${regionId}`)
  }

  const onAddRegion = () => {
    history.push(`/budget-settings/new/${programId}`)
  }

  const { ccAdminCcybClgSettingsD241017 } = useFlags()

  return (
    <Root>
      <Form onSubmit={handleSubmit}>
        <TextField
          label="Program Id"
          value={form}
          onChange={(e) => setForm(e.target.value)}
        />
        <Button type="submit">Submit</Button>
      </Form>
      {programId && (
        <MainSettings
          programId={programId}
          onEditGroupClick={handleEditGroupClick}
          onEditAccrualPointRegion={onEditAccrualPointRegion}
          onAddRegion={onAddRegion}
          launchDarklyFlags={[
            {
              key: 'ccAdminCcybClgSettingsD241017',
              value: ccAdminCcybClgSettingsD241017,
            },
          ]}
        />
      )}
    </Root>
  )
}

export default ProgramSettingsWrapper
