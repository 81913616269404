import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import BudgetGroupCountry from '../common/models/BugetGroupCountry'
import { YbAccrualPointsRegion } from '../common/models/YbAccrualPointsRegion'
import { GET_BUDGET_GROUP_REGIONS } from '../graphql'

interface Response {
  ybProgramOffsetsByProgramId: {
    program: { accrualPointsRegions: YbAccrualPointsRegion[] }
  }
}

const useGetBudgetGroupCountries = (
  programId: string
): BudgetGroupCountry[] => {
  const { data } = useQuery<Response | undefined>(GET_BUDGET_GROUP_REGIONS, {
    variables: { input: { programId: programId } },
  })

  const result = useMemo(
    () =>
      (data?.ybProgramOffsetsByProgramId.program.accrualPointsRegions || [])
        .map((group) =>
          group.countries.map((country) => ({
            country,
            group: {
              id: group.id,
              defaultRegion: group.defaultRegion,
              name: group.name,
            },
          }))
        )
        .reduce(
          (aggregate, arrayBudgetGroupCountries) => [
            ...aggregate,
            ...arrayBudgetGroupCountries,
          ],
          []
        )
        .sort((a, b) =>
          a.country.countryName.localeCompare(b.country.countryName)
        ),
    [data?.ybProgramOffsetsByProgramId.program.accrualPointsRegions]
  )

  return result
}

export default useGetBudgetGroupCountries
