import React, { useMemo } from 'react'
import { Box, Card, Typography } from '@octanner/prism-core'
import { StyledCardContent } from '../../common/components/Styled'
import { CelebrationLevelGroupConfig } from '../../common/models/CelebrationLevelGroupConfig'
import { rootFactories } from '../../models/CelebrationCard'
import CelebrationTagContainer from './CelebrationTagContainer'
import ConfigDetailContainer from './ConfigDetailContainer'
import MenuButton from '../MenuButton'
import { genYearsText } from './utils'
import CelebrationGroupActionScheduleContainer from '../CelebrationGroupSchedule/CelebrationGroupActionScheduleContainer'

interface Props {
  celebrationGroup: CelebrationLevelGroupConfig
  onEditClick: (groupId?: string) => void
  programId: string
}

export default function CelebrationCard({
  celebrationGroup,
  onEditClick,
  programId,
}: Props): JSX.Element {
  const details = rootFactories.map((factory) => factory(celebrationGroup))
  const yearsText = useMemo(
    () =>
      genYearsText(celebrationGroup.celebrationLevelConfigs.map((c) => c.rank)),
    [celebrationGroup.celebrationLevelConfigs]
  )

  return (
    <Card>
      <StyledCardContent>
        <section data-testid={`celebration-group:${celebrationGroup.id}`}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h5" data-testid="name">
              {celebrationGroup.name}
            </Typography>

            <MenuButton onEditClick={() => onEditClick(celebrationGroup.id)} />
          </Box>

          <CelebrationTagContainer
            details={details.filter((d) => d?.tag === true)}
            milestone={celebrationGroup.milestone}
          />
        </section>
        <section>
          <Typography>Anniversary Years:</Typography>
          <Typography data-testid="anniversary-years">{yearsText}</Typography>
        </section>
        <ConfigDetailContainer details={details} />
        <CelebrationGroupActionScheduleContainer
          showToggleScheduleBtn
          celebrationLevelGroupUuid={celebrationGroup.id}
          programId={programId}
        />
      </StyledCardContent>
    </Card>
  )
}
