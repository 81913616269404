import { Box, Button } from '@octanner/prism-core'
import React from 'react'

interface Props {
  loading: boolean
  onSubmit: (e: React.SyntheticEvent) => void
  onCancel: (e: React.SyntheticEvent) => void
}

const ActionButtons = ({ loading, onSubmit, onCancel }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '8px',
      }}
    >
      <Button
        data-testid="action-form:submit"
        onClick={onSubmit}
        loading={loading}
      >
        Add action
      </Button>
      <Button
        data-testid="action-form:cancel"
        color="secondary"
        onClick={onCancel}
        disabled={loading}
      >
        Cancel
      </Button>
    </Box>
  )
}

export default ActionButtons
