import { useMutation } from '@apollo/client'
import { ProgramRepresentationBase } from '../common/models/ProgramRepresentation'
import YbCreateDefaultProgramInput from '../common/models/YbCreateDefaultProgramInput'
import {
  CREATE_DEFAULT_PROGRAM,
  GET_PROGRAM_OFFSETS_BY_PROGRAM_ID,
} from '../graphql'

const useCreateDefaultProgram = (programUuid: string) =>
  useMutation<
    { defaultProgram: ProgramRepresentationBase },
    { input: YbCreateDefaultProgramInput }
  >(CREATE_DEFAULT_PROGRAM, {
    variables: { input: { coreProgramUuid: programUuid } },
    errorPolicy: 'all',
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_PROGRAM_OFFSETS_BY_PROGRAM_ID,
        variables: { programId: programUuid },
        errorPolicy: 'all',
      },
    ],
  })

export default useCreateDefaultProgram
