import { styled } from '@mui/material/styles'
import { Typography } from '@octanner/prism-core'
import React from 'react'
import { AddNewButton, HeaderContainer } from '../common/components/Styled'
import { ProgramRepresentation } from '../common/models/ProgramRepresentation'
import CelebrationCard from './CelebrationCard/CelebrationCard'

const Header = styled(Typography)`
    margin-bottom: ${({ theme }) => theme.spacing(5)};
  `,
  CardRow = styled('div')`
    display: grid;
    grid-gap: ${({ theme }) => theme.spacing(5)};
  `

interface Props {
  onEditGroupClick: (groupId?: string) => void
  program?: ProgramRepresentation
  hideEdit?: boolean
}

export default function CelebrationGroupView({
  onEditGroupClick,
  program,
  hideEdit,
}: Props): JSX.Element {
  return (
    <section data-testid="mainSettings:celebrationGroup:container">
      <HeaderContainer>
        <Header variant="h3">Celebration Groups</Header>
        {!hideEdit && (
          <AddNewButton
            data-testid="mainSettings:celebrationGroup:container:addNew"
            variant="text"
            onClick={() => onEditGroupClick()}
          >
            Add New
          </AddNewButton>
        )}
      </HeaderContainer>

      <CardRow>
        {program?.celebrationLevelGroupConfigs.map((celebrationGroup) => (
          <CelebrationCard
            key={celebrationGroup.id}
            celebrationGroup={celebrationGroup}
            onEditClick={onEditGroupClick}
            programId={program?.id}
          />
        ))}
      </CardRow>
    </section>
  )
}
