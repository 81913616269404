import React from 'react'
import { DetailViewModel } from '../../models/CelebrationCard'
import ConfigDetail from './ConfigDetail'
import { Box } from '@octanner/prism-core'

interface Props {
  details: DetailViewModel[]
}

export default function CelebrationDetailContainer({
  details,
}: Props): JSX.Element {
  return (
    <Box
      component={'section'}
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          sm: 'repeat(2, auto)',
          md: 'repeat(3, auto)',
          lg: 'repeat(4, auto)',
        },
        gridAutoFlow: 'row',
        gap: '2px',
        listStyle: 'none',
        padding: 0,
        margin: '0px 14px',
      }}
    >
      {details
        .filter((d) => !d?.tag)
        .filter((d) => d?.checked)
        .map((d) => d && <ConfigDetail key={d.text} displayText={d.text} />)}
    </Box>
  )
}
