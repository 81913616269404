import { FetchResult, MutationResult, useMutation } from '@apollo/client'
import { YbAccrualPointsRegion } from '../common/models/YbAccrualPointsRegion'
import {
  BaseAccrualPointsConfig,
  YbCreateAccrualPointsConfig,
} from '../common/models/YbUpdateAccrualPointsConfig'
import {
  GET_PROGRAM_OFFSETS_BY_PROGRAM_ID,
  YB_CREATE_ACCRUAL_POINTS_CONFIG,
} from '../graphql'

type Data = { ybAccrualPointsRegion: YbAccrualPointsRegion }
1
type Variables = {
  input: YbCreateAccrualPointsConfig
}

type CreateConfig = (
  programId: string,
  baseConfig: BaseAccrualPointsConfig
) => Promise<FetchResult<Data>>

export default function useCreateAccrualPointsConfig(): [
  CreateConfig,
  MutationResult<Data>
] {
  // Pass mutation to useMutation
  const [mutate, result] = useMutation<Data, Variables>(
    YB_CREATE_ACCRUAL_POINTS_CONFIG
  )

  const createConfig: CreateConfig = (programId, baseConfig) => {
    return mutate({
      variables: { input: { programId, ...baseConfig } },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_PROGRAM_OFFSETS_BY_PROGRAM_ID,
          variables: { programId },
        },
      ],
    })
  }
  return [createConfig, result]
}
