import { useQuery } from '@apollo/client'
import { YbAccrualPointsRegion } from '../common/models/YbAccrualPointsRegion'
import { GET_BUDGET_GROUP_REGIONS } from '../graphql'

interface Response {
  ybProgramOffsetsByProgramId: {
    program: { accrualPointsRegions: YbAccrualPointsRegion[] }
  }
}

const useGetBudgetGroupRegions = (
  programId: string
): YbAccrualPointsRegion[] => {
  const { data } = useQuery<Response | undefined>(GET_BUDGET_GROUP_REGIONS, {
    variables: { input: { programId: programId } },
  })

  return data?.ybProgramOffsetsByProgramId.program.accrualPointsRegions
}

export default useGetBudgetGroupRegions
