export const getItem = (key: string): any =>
  JSON.parse(window.localStorage.getItem(key))

export const storeItem = (key: string, value: any) =>
  window.localStorage.setItem(key, JSON.stringify(value))

export const clearItem = (key: string): void =>
  window.localStorage.removeItem(key)

export const popItem = (key: string): any => {
  const value = getItem(key)
  clearItem(key)
  return value
}
