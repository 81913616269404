import {
  Alert,
  Box,
  Button,
  Collapse,
  IconButton,
  Typography,
} from '@octanner/prism-core'
import { Close } from '@octanner/prism-icons'
import useDeleteActionScheduleTemplate from 'library/src/hooks/useDeleteActionScheduleTemplate'
import React, { useState } from 'react'
import { useGroupSettingsDispatch } from '../../contexts/GroupSettingsContext'
import useCreateActionScheduleTemplate from '../../hooks/useCreateActionScheduleTemplate'
import { useGetActionScheduleTemplatesByCLGId } from '../../hooks/useGetActionScheduleTemplatesByCLGId'
import usePatchCelebrationConfig from '../../hooks/usePatchCelebrationConfig'
import ActionForm, { FormData } from '../ActionForm/ActionForm'
import ConfirmationModal from '../ConfirmationModal'
import CelebrationGroupSchedule from './CelebrationGroupSchedule'
import { buildStartOffset } from './utils'

interface Props {
  celebrationLevelGroupUuid: string
  displayHeading?: boolean
  showToggleScheduleBtn?: boolean
  programId: string
}

const CelebrationGroupActionScheduleContainer = ({
  celebrationLevelGroupUuid,
  displayHeading = false,
  showToggleScheduleBtn = false,
  programId,
}: Props) => {
  const [isAddActionOpen, setIsAddActionOpen] = useState(false)
  const [alert, setAlert] = useState<{
    type: 'error' | 'warning' | 'info' | 'success' | 'secondary'
    message: string
  } | null>(null)
  const [deleteActionState, setDeleteActionState] = useState({
    isOpen: false,
    name: '',
    actionScheduleTemplateUuid: '',
  })

  const [patchConfig] = usePatchCelebrationConfig()
  const dispatch = useGroupSettingsDispatch()
  const { actionScheduleTemplates, loading } =
    useGetActionScheduleTemplatesByCLGId(celebrationLevelGroupUuid)
  const { deleteActionScheduleTemplate, loading: deleteASTLoading } =
    useDeleteActionScheduleTemplate()
  const {
    createActionScheduleTemplate,
    loading: astLoading,
    error,
  } = useCreateActionScheduleTemplate()

  const resetDeleteActionState = () => {
    setDeleteActionState({
      isOpen: false,
      name: '',
      actionScheduleTemplateUuid: '',
    })
  }

  const handleSubmit = async (formInput: FormData) => {
    if (formInput?.surveyUrl) {
      dispatch({ type: 'setSurveyUrl', surveyUrl: formInput.surveyUrl })
      await patchConfig({
        variables: {
          input: {
            config: {
              id: celebrationLevelGroupUuid,
              surveyUrl: formInput.surveyUrl,
            },
            programId: programId,
          },
        },
      })
    }
    await createActionScheduleTemplate({
      input: {
        celebrationLevelGroupUuid,
        // @ts-ignore - we do form validation to ensure this is not an empty string
        taskScheduleGroup: formInput.taskScheduleGroup.value,
        startOffset: buildStartOffset(formInput.startOffset, formInput.when),
        offsetFrom: formInput.offsetFrom,
        addInternationalShippingOffset:
          formInput.addInternationalShippingOffset,
        respectsPrivacy: false,
        endOffset: 0,
      },
      onSuccess: () => {
        setAlert({
          type: 'success',
          message: `${formInput.taskScheduleGroup.label} has been successfuly added.`,
        })
        setIsAddActionOpen(false)
      },
      onError: (err) => {
        setAlert({
          type: 'error',
          message: err.message,
        })
      },
    })
  }

  const handleDelete = async () => {
    await deleteActionScheduleTemplate({
      input: {
        actionScheduleTemplateUuid:
          deleteActionState.actionScheduleTemplateUuid,
      },
      celebrationLevelGroupUuid,
      onSuccess: () => {
        setAlert({
          type: 'info',
          message: `${deleteActionState.name} has been successfuly deleted.`,
        })
        resetDeleteActionState()
      },
      onError: (err) => {
        setAlert({
          type: 'error',
          message: err.message,
        })
        resetDeleteActionState()
      },
    })
  }

  return (
    <>
      {displayHeading && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h3">Celebration timeline</Typography>
            {!isAddActionOpen ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setIsAddActionOpen(true)}
              >
                Add action
              </Button>
            ) : (
              <IconButton
                aria-label="menu button"
                sx={{ marginRight: '8px' }}
                onClick={() => setIsAddActionOpen(false)}
              >
                <Close />
              </IconButton>
            )}
          </Box>
          {alert && (
            <Box
              sx={{
                margin: '16px 0',
              }}
            >
              <Alert severity={alert.type} onClose={() => setAlert(null)}>
                {alert.message}
              </Alert>
            </Box>
          )}
          <Collapse in={isAddActionOpen}>
            <Box>
              <ActionForm
                actionScheduleTemplates={actionScheduleTemplates}
                onSave={(input) => handleSubmit(input)}
                onCancel={() => setIsAddActionOpen(false)}
                error={error}
                loading={astLoading}
              />
            </Box>
          </Collapse>
        </>
      )}

      {actionScheduleTemplates?.length > 0 && (
        <CelebrationGroupSchedule
          loading={loading}
          actionScheduleTemplates={actionScheduleTemplates}
          showToggleScheduleBtn={showToggleScheduleBtn}
          celebrationLevelGroupUuid={celebrationLevelGroupUuid}
          onDeleteActionTemplate={({ actionScheduleTemplateUuid, astName }) => {
            setDeleteActionState({
              isOpen: true,
              name: astName,
              actionScheduleTemplateUuid,
            })
          }}
        />
      )}
      <ConfirmationModal
        open={deleteActionState.isOpen}
        loading={deleteASTLoading}
        titleText="Delete action"
        destroy
        dataTestId="deleteActionConfirmation"
        messageText="This can't be undone. Are you sure?"
        primaryButtonText="Delete action"
        onModalClose={resetDeleteActionState}
        onModalAction={handleDelete}
      />
    </>
  )
}

export default CelebrationGroupActionScheduleContainer
