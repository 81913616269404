import { useMutation } from '@apollo/client'
import { CelebrationLevelGroupConfig } from '../common/models/CelebrationLevelGroupConfig'
import { YbCelebrationLevelGroupConfigUpdateInput } from '../common/models/YbCelebrationLevelGroupConfigUpdateInput'
import { PATCH_CELEBRATION_CONFIG } from '../graphql'

const usePatchCelebrationConfig = () =>
  useMutation<
    { ybPatchCelebrationLevelGroupConfigs: CelebrationLevelGroupConfig },
    { input: YbCelebrationLevelGroupConfigUpdateInput }
  >(PATCH_CELEBRATION_CONFIG)

export default usePatchCelebrationConfig
