import { Alert, Box, TextField, Typography } from '@octanner/prism-core'
import React, { useState } from 'react'
import { Control, Controller, FieldErrorsImpl } from 'react-hook-form'
import { FormData } from './ActionForm'

interface Props {
  control: Control<FormData>
  errors: Partial<FieldErrorsImpl<FormData>>
}

const SurveyUrlInput = ({ control, errors }: Props) => {
  const [surveyUrlAlert, setSurveyUrlAlert] = useState(false)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
      }}
    >
      <Typography variant="h4">Survey url</Typography>
      <Controller
        name="surveyUrl"
        control={control}
        rules={{
          required: 'This field is required',
          validate: (value) =>
            /^https?:\/\/[^\s$.?#].[^\s]*$/i.test(value) || 'Invalid URL.',
        }}
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors.surveyUrl}
            helperText={errors.surveyUrl?.message}
            label="Survey url"
          />
        )}
      />
      {surveyUrlAlert && (
        <Box
          sx={{
            mt: '16px',
          }}
        >
          <Alert onClose={() => setSurveyUrlAlert(false)} severity="warning">
            Survey will arrive before the all of the celebration actions are
            complete.
          </Alert>
        </Box>
      )}
    </Box>
  )
}

export default SurveyUrlInput
