import { useQuery } from '@apollo/client'
import { GET_CELEBRATION_LEVELS } from '../graphql'

type Response = {
  ybProgramConfigs: {
    pageConnection: {
      nodes: {
        celebrationLevelGroupConfigs: {
          celebrationLevelConfigs: {
            rank: number
          }[]
        }[]
      }[]
    }
  }
}

export const useGetCelebrationLevels = ({
  programId,
  customerUuid,
}: {
  programId: string
  customerUuid: string
}) => {
  const { data, loading } = useQuery<Response>(GET_CELEBRATION_LEVELS, {
    variables: {
      input: {
        customerUuid,
        filters: {
          programId,
        },
      },
    },
    skip: !programId || !customerUuid,
  })
  let ranks: number[] = []
  if (data) {
    const nodes = data.ybProgramConfigs.pageConnection.nodes

    ranks = nodes
      .flatMap((node) =>
        node.celebrationLevelGroupConfigs.flatMap((groupConfig) =>
          groupConfig.celebrationLevelConfigs.map((config) => config.rank)
        )
      )
      .sort((a, b) => a - b)
  }
  return { data: ranks, loading }
}
