import React from 'react'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import App from './App'
import ReactDOM from 'react-dom'

const cache = createCache({
  key: 'oct',
  nonce:
    document
      .querySelector('meta[name="style-nonce"]')
      ?.getAttribute('content') || undefined,
})

ReactDOM.render(
  <CacheProvider value={cache}>
    <App />
  </CacheProvider>,
  document.getElementById('root')
)
