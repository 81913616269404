import { styled } from '@mui/material/styles'
import { Typography, Button, CardContent } from '@octanner/prism-core'
import colors from '../colors'

export const BasicForm = styled('form')`
    display: grid;
    grid-gap: ${({ theme }) => theme.spacing(5)};
    padding-top: ${({ theme }) => theme.spacing(5)};
    padding-bottom: ${({ theme }) => theme.spacing(5)};
    margin: 0;
    > section + section {
      padding-top: ${({ theme }) => theme.spacing(5)};
      border-top: 1px solid ${colors.gray400};
    }
  `
export const ActionSection = styled('section')`
    display: flex;
    justify-content: center;
    align-items: end;
  `
export const AddNewButton = styled(Button)`
    margin-bottom: 8px;
  `
export const Form = styled(BasicForm)`
    background-color: ${colors.gray300};
    border-top: 1px solid ${colors.gray400};
    border-bottom: 1px solid ${colors.gray400};
    > section {
      padding-left: ${({ theme }) => theme.spacing(5)};
      padding-right: ${({ theme }) => theme.spacing(5)};
    }
  `
export const ActionsContainer = styled('div')`
    grid-column-start: -1;
    > button + button {
      margin-left: ${({ theme }) => theme.spacing(3)};
    }
  `
export const Row = styled('section')`
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-gap: ${({ theme }) => theme.spacing(5)};
  `
export const HeaderContainer = styled('div')`
    display: flex;
    justify-content: space-between;
  `
export const Label = styled(Typography)`
    color: ${colors.gray600};
  `
export const ContentContainer = styled('div')`
    margin-top: ${({ theme }) => theme.spacing(5)};
    display: grid;
    grid-gap: ${({ theme }) => theme.spacing(3)};
    grid-template-columns: 200px auto;
  `
export const Fieldset = styled('fieldset')`
    padding: 0;
    border: 0;
    margin: 0;
  `
export const boxShadow =
  '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.17), 0px 1px 3px rgba(0, 0, 0, 0.2)'
export const PointerLabel = styled('label')`
    display: flex;
    gap: ${({ theme }) => theme.spacing(5)};
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  `
export const CenteredTypography = styled(Typography)`
    margin-top: auto;
    margin-bottom: auto;
  `
export const CheckboxLabel = styled('label')`
    box-sizing: content-box;
    display: flex;
    cursor: pointer;
  `
export const CenteredContent = styled('div')`
    display: flex;
    align-content: center;
    justify-content: center;
  `
export const StyledCardContent = styled(CardContent)`
    height: 100%;
    display: grid;
    grid-gap: ${({ theme }) => theme.spacing(5)};
    grid-template-rows: auto auto auto 1fr;
    padding: ${({ theme }) => theme.spacing(6)};
  `
