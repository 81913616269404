import React, { useState } from 'react'
import {
  Button,
  Box,
  Card,
  Timeline,
  Loader,
  ToggleButton,
  DatePicker,
  AdapterLuxon,
  TextField,
} from '@octanner/prism-core'
import { YbActionScheduleTemplateRepresentation } from 'library/src/models/ActionScheduleTemplate'
import { transformDataToEvents } from './utils'

interface Props {
  loading: boolean
  actionScheduleTemplates: YbActionScheduleTemplateRepresentation[]
  celebrationLevelGroupUuid?: string
  showToggleScheduleBtn?: boolean
  onDeleteActionTemplate: ({ actionScheduleTemplateUuid, astName }) => void
}

const CelebrationGroupSchedule = ({
  loading,
  actionScheduleTemplates,
  celebrationLevelGroupUuid,
  showToggleScheduleBtn = false,
  onDeleteActionTemplate,
}: Props) => {
  const [showSchedule, setShowSchedule] = useState(
    celebrationLevelGroupUuid && showToggleScheduleBtn ? false : true
  )
  const [scheduleView, setScheduleView] = useState<'offsets' | 'preview'>(
    'offsets'
  )
  const [selectedDateObj, setSelectedDateObj] = useState({
    date: new Date(),
    lastValidDate: new Date(),
    isValid: true,
  })

  const handleScheduleViewChange = (_, newAlignment) => {
    setScheduleView(newAlignment)
  }

  const handleDateChange = (date) => {
    setSelectedDateObj({
      date: date,
      lastValidDate: date?.invalid ? selectedDateObj.lastValidDate : date,
      isValid: date?.invalid ? false : true,
    })
  }

  return (
    <>
      {actionScheduleTemplates.length > 0 &&
        celebrationLevelGroupUuid &&
        showToggleScheduleBtn && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: '10px',
            }}
          >
            <Button
              data-testid="celebration-group:schedule-toggle-btn"
              variant="text"
              onClick={() => {
                setShowSchedule((prev) => !prev)
              }}
            >
              {showSchedule ? 'Hide' : 'Show'} schedule
            </Button>
          </Box>
        )}

      {loading && <Loader />}

      {showSchedule && !loading && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginY: '10px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 4,
              }}
            >
              {celebrationLevelGroupUuid && (
                <Box>
                  <ToggleButton
                    options={[
                      {
                        value: 'offsets',
                        label: 'Offsets',
                        icon: '',
                      },
                      {
                        value: 'preview',
                        label: 'Preview',
                        icon: '',
                      },
                    ]}
                    value={scheduleView}
                    onChange={handleScheduleViewChange}
                  />
                </Box>
              )}
              {scheduleView === 'preview' && (
                <DatePicker
                  label="Anniversary Date"
                  dateAdapter={AdapterLuxon}
                  value={selectedDateObj.date}
                  onChange={handleDateChange}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      helperText={
                        !selectedDateObj.isValid
                          ? 'Enter date in DD MMM YYYY format'
                          : ''
                      }
                      error={!selectedDateObj.isValid}
                    />
                  )}
                />
              )}
            </Box>
          </Box>
          <Card
            sx={{
              paddingX: '20px',
            }}
            data-testid="celebration-group:timeline-card"
          >
            <Timeline
              events={transformDataToEvents({
                actionScheduleTemplates,
                scheduleObject: {
                  scheduleType: scheduleView,
                  scheduleDate: selectedDateObj.isValid
                    ? selectedDateObj.date
                    : selectedDateObj.lastValidDate,
                },
                onDeleteClick: onDeleteActionTemplate,
              })}
              variant="complex"
            />
          </Card>
        </>
      )}
    </>
  )
}

export default CelebrationGroupSchedule
