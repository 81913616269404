import { Box } from '@mui/system'
import { TextField, Typography } from '@octanner/prism-core'
import React from 'react'
import { CoreAwardLevelInput } from '../common/models/YbCelebrationLevelGroupConfigInput'

interface PointLevelInputProps {
  config: CoreAwardLevelInput
  handleLevelPointsChange: (value: CoreAwardLevelInput) => void
}

const PointLevelInput = ({
  config,
  handleLevelPointsChange,
}: PointLevelInputProps) => {
  return (
    <Box
      data-testid={`pointLevelConfigEdit:${config.rank}`}
      sx={{
        width: 184,
      }}
    >
      <Typography data-testid="editViewRank" variant="body1">
        {config.rank} Year
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
        }}
      >
        <TextField
          label="Min"
          type="number"
          size="small"
          value={config.userSelectedCoreAwardLevel.minPoints}
          onChange={(e) =>
            handleLevelPointsChange({
              rank: config.rank,
              id: config.id,
              userSelectedCoreAwardLevel: {
                awardLevelUuid:
                  config.userSelectedCoreAwardLevel.awardLevelUuid,
                minPoints: parseInt(e.target.value),
                maxPoints: config.userSelectedCoreAwardLevel.maxPoints,
              },
              modified: true,
            })
          }
          inputProps={{
            'data-testid': `pointLevelConfigEdit:${config.rank}:min`,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="Max"
          type="number"
          size="small"
          value={config.userSelectedCoreAwardLevel.maxPoints}
          onChange={(e) => {
            handleLevelPointsChange({
              rank: config.rank,
              id: config.id,
              userSelectedCoreAwardLevel: {
                awardLevelUuid:
                  config.userSelectedCoreAwardLevel.awardLevelUuid,
                minPoints: config.userSelectedCoreAwardLevel.minPoints,
                maxPoints: parseInt(e.target.value),
              },
              modified: true,
            })
          }}
          inputProps={{
            'data-testid': `pointLevelConfigEdit:${config.rank}:max`,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
    </Box>
  )
}

export default PointLevelInput
