import { Typography } from '@octanner/prism-core'
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors'
import React from 'react'
import { useGroupSettings } from '../../contexts/GroupSettingsContext'
import { YbActionScheduleTemplateRepresentation } from '../../models/ActionScheduleTemplate'

interface Props {
  template: YbActionScheduleTemplateRepresentation
  isSurveyEditMode: boolean
}

export default function TimelineItemFooter({
  template,
  isSurveyEditMode,
}: Props) {
  const groupSettingsContext = useGroupSettings()
  if (template.taskScheduleGroup.code === 'REQUEST_CELEBRANT_FEEDBACK') {
    return (
      <Typography
        sx={{
          borderBottom: isSurveyEditMode
            ? 'none'
            : `1px solid ${tannerGray[200]}`,
          paddingBottom: '16px',
          marginTop: '-16px',
        }}
      >
        {groupSettingsContext?.surveyUrl}
      </Typography>
    )
  }

  return null
}
