import { useMutation } from '@apollo/client'
import {
  DELETE_ACTION_SCHEDULE_TEMPLATE,
  GET_ACTION_SCHEDULE_TEMPLATES_BY_CLG_ID,
} from '../graphql'
import { YbActionScheduleTemplateRepresentation } from '../models/ActionScheduleTemplate'

interface YbDeleteActionScheduleTemplateInput {
  actionScheduleTemplateUuids: string[]
}

interface DeleteActionScheduleTemplateParams {
  input: YbDeleteActionScheduleTemplateInput
  celebrationLevelGroupUuid: string
  onSuccess?: () => void
  onError?: (error: Error) => void
}

const useDeleteActionScheduleTemplate = () => {
  const [mutate, { loading, error }] = useMutation<
    { ybDeleteActionScheduleTemplate: YbActionScheduleTemplateRepresentation },
    { input: YbDeleteActionScheduleTemplateInput }
  >(DELETE_ACTION_SCHEDULE_TEMPLATE)

  const deleteActionScheduleTemplate = async ({
    input,
    celebrationLevelGroupUuid,
    onSuccess,
    onError,
  }: DeleteActionScheduleTemplateParams) => {
    try {
      const { data, errors } = await mutate({
        variables: { input },
        errorPolicy: 'all',
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_ACTION_SCHEDULE_TEMPLATES_BY_CLG_ID,
            variables: {
              input: {
                celebrationLevelGroupUuid,
              },
            },
            errorPolicy: 'all',
          },
        ],
      })
      if (data && onSuccess) onSuccess()

      let errMsg = ''
      errors?.forEach((error) => {
        const splitMsg = error.message.split('The errors are:')
        errMsg.length > 0
          ? (errMsg += ', ' + splitMsg[1])
          : (errMsg += splitMsg[1])
      })

      if (errors) throw new Error(errMsg)
    } catch (err) {
      if (onError) onError(err)
    }
  }
  return { deleteActionScheduleTemplate, loading, error }
}

export default useDeleteActionScheduleTemplate
