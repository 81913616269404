import { useEffect, useRef, useState } from 'react'
import events from '../utilities/events'
import { popItem, storeItem } from '../utilities/persistence'
import { exchangeCode, initiateLogin, InternalState } from './Auth'
import { DateTime } from 'luxon'

type State = 'ready' | 'loading' | 'success' | 'error'

const LOCATION_KEY = 'return_location'

const useEnsureLoggedIn = () => {
  const [decodedToken, setDecodedToken] =
    useState<InternalState['decodedToken']>()
  const intervalId = useRef<number>()
  const { location } = window
  const [state, setState] = useState<State>('ready')
  const onPostLogin = location.pathname === '/login/post-login'

  useEffect(() => {
    if (decodedToken || !onPostLogin || !location.search) return
    const { code, state: returnedState } = location.search
      .replace(/\?+|\?+$/, '')
      .split('&')
      .map((i) => i.split('='))
      .reduce((agg, cur) => ({ ...agg, [cur[0]]: cur[1] }), {}) as {
      code: string
      state: string
    }
    setState('loading')
    try {
      exchangeCode(code, returnedState).then(() => setState('success'))
      const { path, state } = popItem(LOCATION_KEY) as {
        path: string
        state: any
      }
      window.history.replaceState(state, '', path)
    } catch {
      setState('error')
    }
  }, [location.pathname, location.search])

  useEffect(() => {
    return events.subscribe('auth', ({ decodedToken }) => {
      setDecodedToken(decodedToken)
    })
  }, [])

  useEffect(() => {
    if (decodedToken || onPostLogin) return
    storeItem(LOCATION_KEY, {
      path: window.location.pathname + window.location.search,
      state: history.state,
    })
    initiateLogin()
  }, [decodedToken])

  useEffect(() => {
    const clearIntervalId = () =>
      intervalId.current && window.clearInterval(intervalId.current)
    if (!decodedToken) {
      clearIntervalId()
      return
    }

    intervalId.current = window.setInterval(() => {
      const expiresDate = DateTime.fromSeconds(decodedToken.exp)
      if (expiresDate > DateTime.now()) return
    }, 60000)

    return clearIntervalId
  }, [decodedToken])

  return state
}

export default useEnsureLoggedIn
