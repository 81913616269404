import { useQuery } from '@apollo/client'
import { GET_ACTION_SCHEDULE_TEMPLATES_BY_CLG_ID } from '../graphql'
import { YbActionScheduleTemplateRepresentation } from '../models/ActionScheduleTemplate'

export interface YbGetActionScheduleTemplatesByCelebrationLevelGroupUuidPayload {
  ybGetActionScheduleTemplatesByCelebrationLevelGroupId: {
    actionScheduleTemplates: YbActionScheduleTemplateRepresentation[]
  }
}

export const useGetActionScheduleTemplatesByCLGId = (
  celebrationLevelGroupUuid: string
) => {
  const { data, loading } =
    useQuery<YbGetActionScheduleTemplatesByCelebrationLevelGroupUuidPayload>(
      GET_ACTION_SCHEDULE_TEMPLATES_BY_CLG_ID,
      {
        variables: {
          input: {
            celebrationLevelGroupUuid,
          },
        },
        skip: !celebrationLevelGroupUuid,
      }
    )
  return {
    actionScheduleTemplates:
      data?.ybGetActionScheduleTemplatesByCelebrationLevelGroupId
        ?.actionScheduleTemplates ?? [],
    loading,
  }
}
