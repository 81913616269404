export function genYearsText(years: number[]): string {
  return years
    .sort((a, b) => a - b)
    .reduce((text, year, index, sorted) => {
      if (!index) return year.toString()

      const previousYear = sorted[index - 1]
      const isDash = text.slice(-1) === '-'
      const diff = Math.abs(year - previousYear)
      const isLast = index + 1 === sorted.length

      if (isDash && diff === 1)
        return text.concat(isLast ? year.toString() : '')
      if (diff === 1) return text.concat(isLast ? `, ${year}` : '-')
      if (isDash) return text.concat(`${previousYear}, ${year}`)
      return text.concat(`, ${year}`)
    }, '')
}
