import { useMutation } from '@apollo/client'
import { CREATE_NEW_CELEBRATION } from '../graphql'
import { useState } from 'react'

type YbCreateCelebrationWithoutEligibilityInput = {
  identityUuid: string
  celebrationDate: string
  celebrationLevel: number
  programUuid: string
}[]

export interface YbCreateCelebrationWithoutEligibility {
  results: {
    identityUuid?: string
    message?: string
    status: string
  }[]
}

interface CreateNewCelebrationParams {
  input: YbCreateCelebrationWithoutEligibilityInput
  onSuccess?: () => void
  onError?: (error: Error) => void
  onSettled?: () => void
}

const useCreateNewCelebration = () => {
  const [allErrors, setAllErrors] = useState('')

  const [mutate, { loading }] = useMutation<
    {
      ybCreateCelebrationWithoutEligibility: YbCreateCelebrationWithoutEligibility
    },
    { input: YbCreateCelebrationWithoutEligibilityInput }
  >(CREATE_NEW_CELEBRATION)

  const createNewCelebration = async ({
    input,
    onSuccess,
    onError,
    onSettled,
  }: CreateNewCelebrationParams) => {
    try {
      const { data, errors } = await mutate({
        variables: { input },
        errorPolicy: 'all',
      })

      let errMsg = ''
      errors?.forEach((error) => {
        const splitMsg = error.message.split('The errors are:')
        errMsg.length > 0
          ? (errMsg += ', ' + splitMsg[1])
          : (errMsg += splitMsg[1])
      })

      if (errors) {
        setAllErrors(errMsg)
        throw new Error(errMsg)
      }

      if (
        // @ts-ignore type name should be returned by GraphQL
        data?.ybCreateCelebrationWithoutEligibility.results[0].__typename ===
        'YbCreateCelebrationError'
      ) {
        setAllErrors(
          data.ybCreateCelebrationWithoutEligibility.results[0].message
        )
      }
      if (data && onSuccess) onSuccess()
    } catch (err) {
      if (onError) onError(err)
    } finally {
      onSettled?.()
    }
  }
  const errorMessage = { message: allErrors }
  return { createNewCelebration, loading, errorMessage, setAllErrors }
}

export default useCreateNewCelebration
