import { Checkbox } from '@octanner/prism-core'
import {
  CenteredContent,
  CenteredTypography,
  CheckboxLabel,
} from '../common/components/Styled'
import React from 'react'
import { CountryFormInput } from '../models/BudgetGroupForm'

const CountryInput: React.FC<{
  countryState: CountryFormInput
  readOnly: boolean
  onChange: (iso2Code: string, checked: boolean) => void
}> = ({ countryState, onChange, readOnly }) => (
  <CheckboxLabel
    key={countryState.iso2Code}
    htmlFor={`country-${countryState.iso2Code}`}
  >
    <CenteredContent>
      {!readOnly && (
        <Checkbox
          disableRipple
          disabled={Boolean(countryState.regionName)}
          id={`country-${countryState.iso2Code}`}
          checked={countryState.checked}
          onChange={(e) => onChange(countryState.iso2Code, e.target.checked)}
          inputProps={{
            // @ts-ignore actual prop on input element
            'data-testid': `budget:form:countries:input:${countryState.iso2Code}`,
          }}
        />
      )}
      <CenteredTypography>
        {countryState.countryName}{' '}
        {countryState.regionName && `(${countryState.regionName})`}
      </CenteredTypography>
    </CenteredContent>
  </CheckboxLabel>
)

export default React.memo(CountryInput)
