import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { ThemeProvider } from '@octanner/prism-core'
import React from 'react'
import packageJson from '../../package.json'
import { apolloAuthLink } from '../auth/Auth'
import { withLDProvider } from 'launchdarkly-react-client-sdk'

const cache = new InMemoryCache()

const AppProviders: React.FC = ({ children }) => {
  const client = new ApolloClient({
    link: apolloAuthLink.concat(createHttpLink({ uri: '/graphql' })),
    cache,
    name: packageJson.name,
    version: packageJson.version,
  })
  const initApp = () => (
    <ApolloProvider client={client}>
      <ThemeProvider disableInjection>{children}</ThemeProvider>
    </ApolloProvider>
  )

  const LDProvider = withLDProvider({
    // @ts-ignore - LAUNCH_DARKLY_CLIENT_ID is defined in the webpack config
    clientSideID: LAUNCH_DARKLY_CLIENT_ID,
  })(initApp)

  return <LDProvider />
}

export default AppProviders
