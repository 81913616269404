import { TimelineItem } from '@octanner/prism-core/Timeline/types'
import { addDays, format } from 'date-fns'
import React from 'react'
import { YbActionScheduleTemplateRepresentation } from '../../models/ActionScheduleTemplate'
import MenuButton from '../MenuButton'
import TimelineItemFooter from './TimelineItemFooter'

const buildTimeFrameUnit = (
  startOffset: number,
  offsetFrom: string
): string => {
  const offsetFromFormatted = offsetFrom.replace('_', ' ').toLowerCase()
  if (startOffset > 0) {
    return `days after ${offsetFromFormatted}`
  } else if (startOffset < 0) {
    return `days before ${offsetFromFormatted}`
  } else {
    return `${offsetFromFormatted
      .charAt(0)
      .toUpperCase()}${offsetFromFormatted.slice(1)}`
  }
}

const sortActionScheduleTemplates = (
  data: YbActionScheduleTemplateRepresentation[]
) => {
  return [...data].sort((a, b) => a.startOffset - b.startOffset)
}

interface TransformDataToEventsProps {
  actionScheduleTemplates: YbActionScheduleTemplateRepresentation[]
  scheduleObject: { scheduleType: 'offsets' | 'preview'; scheduleDate: Date }
  editingTemplate: YbActionScheduleTemplateRepresentation | null
  onDeleteClick: ({ actionScheduleTemplateUuid, astName }) => void
  setEditingTemplate: (template: YbActionScheduleTemplateRepresentation) => void
  editForm: () => JSX.Element
  isEditMode: boolean
}

export const transformDataToEvents = ({
  actionScheduleTemplates,
  scheduleObject,
  editingTemplate,
  onDeleteClick,
  setEditingTemplate,
  editForm,
  isEditMode,
}: TransformDataToEventsProps) => {
  // add your action to this list as you support adding/deleting more actions. will remove when all actions are supported
  const allowDelete = [
    'NOTIFY_CELEBRANT_ANNIVERSARY_DAY',
    'NOTIFY_MANAGER_PREPARE_PRESENTATION',
    'UPDATE_CELEBRATION_MANAGER',
    'NOTIFY_MANAGER_ANNIVERSARY_DAY_CERTIFICATE',
    'NOTIFY_CELEBRANT_ANNIVERSARY_DAY_CERTIFICATE',
    'NOTIFY_CELEBRANT_INVITE_PEERS',
    'REGISTER_INVITES',
    'AUTOSHIP_AWARD_ORDER',
    'DEPOSIT_ACCRUAL_POINTS',
    'NOTIFY_CELEBRANT_ACCRUAL_POINTS_DEPOSITED',
    'NOTIFY_CELEBRANT_ORDER_FROM_CATALOG',
    'REQUEST_CELEBRANT_FEEDBACK',
    'DEPOSIT_POINTS',
    'REQUEST_MANAGER_PERSONAL_NOTE',
    'NOTIFY_MANAGER_ANNIVERSARY_DAY',
    'NOTIFY_CELEBRANT_VIEW_YEARBOOK',
    'NOTIFY_PEERS_ANNIVERSARY_DAY',
    'REQUEST_PEERS_PERSONAL_NOTE',
  ]
  const events = sortActionScheduleTemplates(actionScheduleTemplates).map(
    (template) => {
      let timeFrame
      let timeFrameUnit

      if (scheduleObject.scheduleType === 'offsets') {
        timeFrame =
          template.startOffset === 0 ? '' : Math.abs(template.startOffset)
        timeFrameUnit = buildTimeFrameUnit(
          template.startOffset,
          template.offsetFrom
        )
      } else if (scheduleObject.scheduleType === 'preview') {
        timeFrame = ''
        const adjustedDate = addDays(
          scheduleObject.scheduleDate,
          template.startOffset
        )
        timeFrameUnit = format(adjustedDate, 'dd MMM yyyy')
      }

      const event: TimelineItem = {
        // @ts-ignore - we need this to be an empty string to get the correct view
        timeFrame,
        timeFrameUnit,
        title: template.taskScheduleGroup.description,
        tags: [{ title: template.taskScheduleGroup.scheduleGroupCategory }],
        button: isEditMode
          ? () => {
              if (allowDelete.includes(template.taskScheduleGroup.code)) {
                return (
                  <MenuButton
                    onDeleteClick={() =>
                      onDeleteClick({
                        astName: template.taskScheduleGroup.description,
                        actionScheduleTemplateUuid:
                          template.actionScheduleTemplateUuid,
                      })
                    }
                    onEditClick={() => {
                      setEditingTemplate(template)
                    }}
                  />
                )
              }
            }
          : null,
        timelineItemFooter: () => <TimelineItemFooter template={template} />,
        customFooter: () =>
          editingTemplate?.actionScheduleTemplateUuid ===
          template.actionScheduleTemplateUuid
            ? editForm()
            : null,
        shouldHaveBorderBottom:
          editingTemplate?.actionScheduleTemplateUuid ===
          template.actionScheduleTemplateUuid
            ? false
            : true,
      }

      if (template.taskScheduleGroup.numReminders > 0) {
        event.tags.push({
          title: `${template.taskScheduleGroup.numReminders} Reminders`,
          infoText: `Reminder emails are sent every 7 days following the initial notification.`,
        })
      }

      if (template.addInternationalShippingOffset) {
        event.tags.push({
          title: '+7 Days',
          infoText: 'International orders are shipped 7 days earlier.',
        })
      }

      if (template.respectsPrivacy) {
        event.tags.push({
          title: 'Privacy',
          infoText: 'Removed from timline when privacy settings are turned on.',
        })
      }

      return event
    }
  )
  return events
}

export const buildStartOffset = (startOffset: number, when: string) => {
  if (when === 'before') {
    return -Number(startOffset)
  } else {
    return Number(startOffset)
  }
}
