import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@octanner/prism-core'

const StyledCheck = styled('li')`
  ::marker {
    font-size: 10px;
  }
`

interface ConfigDetailProps {
  displayText: string
}

export default function ConfigDetail({ displayText }: ConfigDetailProps) {
  return (
    <Box
      component={'ul'}
      sx={{
        margin: '0px 8px',
        padding: 1,
      }}
    >
      <StyledCheck>
        <Typography data-testid={`config-detail:${displayText}`}>
          {displayText}
        </Typography>
      </StyledCheck>
    </Box>
  )
}
