const events = (function () {
  const topics = {}
  const hOP = topics.hasOwnProperty

  return {
    subscribe: function (topic: string, listener: (obj: any) => void) {
      // Create the topic's object if not yet created
      if (!hOP.call(topics, topic)) topics[topic] = []

      // Add the listener to queue
      const index = topics[topic].push(listener) - 1

      // Provide handle back for removal of topic
      return () => {
        delete topics[topic][index]
      }
    },
    publish: function (topic: string, info: any) {
      // If the topic doesn't exist, or there's no listeners in queue, just leave
      if (!hOP.call(topics, topic)) return

      // Cycle through topics queue, fire!
      topics[topic].forEach(function (listener: (obj: any) => void) {
        listener(info != undefined ? info : {})
      })
    },
  }
})()

export default events
