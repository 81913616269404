import { useMutation } from '@apollo/client'
import {
  CREATE_ACTION_SCHEDULE_TEMPLATE,
  GET_ACTION_SCHEDULE_TEMPLATES_BY_CLG_ID,
} from '../graphql'
import {
  YbActionScheduleTemplateRepresentation,
  YbCreateActionScheduleTemplateInput,
} from '../models/ActionScheduleTemplate'

interface CreateActionScheduleTemplateParams {
  input: YbCreateActionScheduleTemplateInput
  onSuccess?: () => void
  onError?: (error: Error) => void
}

const useCreateActionScheduleTemplate = () => {
  const [mutate, { loading, error }] = useMutation<
    { ybCreateActionScheduleTemplate: YbActionScheduleTemplateRepresentation },
    { input: YbCreateActionScheduleTemplateInput }
  >(CREATE_ACTION_SCHEDULE_TEMPLATE)

  const createActionScheduleTemplate = async ({
    input,
    onSuccess,
    onError,
  }: CreateActionScheduleTemplateParams) => {
    try {
      const { data, errors } = await mutate({
        variables: { input },
        errorPolicy: 'all',
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_ACTION_SCHEDULE_TEMPLATES_BY_CLG_ID,
            variables: {
              input: {
                celebrationLevelGroupUuid: input.celebrationLevelGroupUuid,
              },
            },
            errorPolicy: 'all',
          },
        ],
      })
      if (data && onSuccess) onSuccess()
      if (errors) throw new Error('Error creating action schedule template')
    } catch (err) {
      if (onError) onError(err)
    }
  }
  return { createActionScheduleTemplate, loading, error }
}

export default useCreateActionScheduleTemplate
