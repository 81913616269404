import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from '@octanner/prism-core'
import React from 'react'
import {
  useGroupSettings,
  useGroupSettingsDispatch,
} from '../contexts/GroupSettingsContext'
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors'
import YearbookSettings from './YearbookSettings'

const dataTestPrefix = 'yps:yearbooks'

export default function Yearbooks() {
  const groupSettingsContext = useGroupSettings()
  const dispatch = useGroupSettingsDispatch()

  return (
    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
      <FormGroup
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          borderTop: `1px solid ${tannerGray[200]}`,
        }}
        data-testid={dataTestPrefix}
      >
        <Typography
          variant="h3"
          sx={{
            pt: 4,
          }}
        >
          Yearbook Settings
        </Typography>

        <FormControlLabel
          control={
            <Switch
              sx={{
                ml: 4,
              }}
              checked={groupSettingsContext?.yearbookEnabled}
              onChange={() => dispatch({ type: 'toggleYearbook' })}
              name="toggleYearbook"
              color="primary"
              data-testid={`${dataTestPrefix}:toggleYearbook`}
            />
          }
          label="Yearbook"
          tabIndex={0}
        />

        {groupSettingsContext?.yearbookEnabled && (
          <>
            <YearbookSettings
              groupSettingsContext={groupSettingsContext}
              dispatch={dispatch}
            />
          </>
        )}
      </FormGroup>
    </FormControl>
  )
}
