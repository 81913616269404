import { styled } from '@mui/material/styles'
import { Alert, Button, TextField, Typography } from '@octanner/prism-core'
import { BasicForm, Fieldset } from '../common/components/Styled'
import BudgetGroupCountry from '../common/models/BugetGroupCountry'
import { CelebrationLevelConfigBase } from '../common/models/CelebrationLevelConfig'
import { YbAccrualPointsRegion } from '../common/models/YbAccrualPointsRegion'
import { BaseAccrualPointsConfig } from '../common/models/YbUpdateAccrualPointsConfig'
import React, { useCallback, useState } from 'react'
import {
  countryErrorMessage,
  CountryFormInput,
  groupNameErrorMessage,
  isValidCountry,
  LevelFormItem,
  pointsErrorMessage,
  validateLevelForm,
} from '../models/BudgetGroupForm'
import CountryInput from './CountryInput'
import ConfirmationModal from './ConfirmationModal'

const FooterContainer = styled('section')<{ alignment: string }>`
  display: flex;
  justify-content: ${(props) => props.alignment};
`
const RegionNameInput = styled(TextField)`
  width: 410px;
`
const FooterButton = styled(Button)`
  margin-left: ${({ theme }) => theme.spacing(3)};
`
const CountryContainer = styled('div')`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${({ theme }) => theme.spacing(3)};
`
const PointsContainer = styled(Fieldset)`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: ${({ theme }) => theme.spacing(3)};
`
const FormHeader = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(5)};
`
const PointsLabel = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`
const SpacedAlert = styled(Alert)`
  margin-bottom: ${({ theme }) => theme.spacing(5)};
`

const levelsToState = (
  levels: CelebrationLevelConfigBase[],
  defaultValue: number,
  budgetGroup?: YbAccrualPointsRegion
): LevelFormItem[] =>
  levels
    .map((level) => ({
      ...level,
      value: getPointsValue(defaultValue, level.rank, budgetGroup),
    }))
    .sort((a, b) => a.rank - b.rank)

const getPointsValue = (
  defaultValue: number,
  rank: number,
  budgetGroup?: YbAccrualPointsRegion
): string => {
  const existing = budgetGroup?.values.find(
    (pv) => pv.celebrationLevel.rank === rank
  )
  return (existing?.pointsAmount || defaultValue).toString()
}

const countriesToState = (
  bgCountries: BudgetGroupCountry[],
  region?: YbAccrualPointsRegion
): CountryFormInput[] => {
  const selectedIso2Codes = region?.countries.map((c) => c.iso2Code) || [],
    otherSelectedIso2Codes = bgCountries
      .filter((bgc) => !bgc.group.defaultRegion)
      .map((c) => c.country.iso2Code),
    allSelectedIso2Codes = [...selectedIso2Codes, ...otherSelectedIso2Codes]

  return bgCountries.map((bgCountry) => ({
    ...bgCountry.country,
    checked: allSelectedIso2Codes.includes(bgCountry.country.iso2Code),
    regionName:
      bgCountry.group.defaultRegion || bgCountry.group.id === region?.id
        ? undefined
        : bgCountry.group.name,
  }))
}

interface Props {
  budgetGroup?: YbAccrualPointsRegion
  levels: CelebrationLevelConfigBase[]
  onSubmit: (
    input: Omit<BaseAccrualPointsConfig, 'accrualPointsRegionId'>
  ) => void
  onCancel: () => void
  countries: BudgetGroupCountry[]
  defaultValue: number
  onDelete: () => void
}

const BudgetGroupForm: React.FC<Props> = ({
  onSubmit,
  budgetGroup,
  countries,
  levels,
  defaultValue,
  onCancel,
  onDelete,
}) => {
  const [regionName, setRegionName] = useState(budgetGroup?.name || '')
  const [submitted, setSubmitted] = useState(false)
  const [deleteGroup, setDeleteGroup] = useState(false)

  const [countriesState, setCountriesState] = useState(() =>
    countriesToState(countries, budgetGroup)
  )
  const [levelPoints, setLevelPoints] = useState(
    levelsToState(levels, defaultValue, budgetGroup)
  )

  const handleLevelPointsChange = (rank: number, value: string) =>
    setLevelPoints((previousState) => {
      const rankIndex = previousState.findIndex((lp) => lp.rank === rank),
        levelPoint = previousState[rankIndex],
        updatedLevelPoint = validateLevelForm({ ...levelPoint, value })
      return [
        ...previousState.slice(0, rankIndex),
        updatedLevelPoint,
        ...previousState.slice(rankIndex + 1),
      ]
    })

  const handleSubmit = (event: React.FormEvent) => {
    const validCountries = countriesState
      .filter(isValidCountry)
      .map((c) => c.iso2Code)
    event.preventDefault()
    setSubmitted(true)

    const trimmedName = regionName.trim()
    if (!trimmedName.length) return
    if (!validCountries.length) return
    if (levelPoints.some((lp) => lp.errors?.length)) return
    onSubmit({
      name: trimmedName,
      countries: validCountries,
      values: levelPoints.map((l) => ({
        levelId: l.id,
        pointsAmount: +l.value,
      })),
    })
  }

  const handleChange = useCallback((iso2Code: string, checked: boolean) => {
    setCountriesState((previousState) => {
      const index = previousState.findIndex((c) => c.iso2Code === iso2Code)
      if (index < 0) {
        return previousState
      }
      const updatedCountry = {
        ...previousState[index],
        checked,
      }
      return [
        ...previousState.slice(0, index),
        updatedCountry,
        ...previousState.slice(index + 1),
      ]
    })
  }, [])

  const hasLevelPointErrors = levelPoints.some((lp) => lp.errors?.length)
  const hasNameError = !regionName.trim().length
  const hasCountryError = !countriesState.some(isValidCountry)
  const numberOfRows = Math.ceil(countriesState.length / 4)
  const listedCountries = budgetGroup?.defaultRegion
    ? countriesState.filter((c) => !c.regionName)
    : countriesState

  return (
    <>
      <BasicForm data-testid="budget:form" onSubmit={handleSubmit}>
        <section>
          <FormHeader variant="h2"> Edit Group </FormHeader>
          {hasNameError && submitted && (
            <SpacedAlert severity="error">
              <span data-testid="budget:form:validation:name">
                {groupNameErrorMessage}
              </span>
            </SpacedAlert>
          )}
          <RegionNameInput
            label="Group Name"
            value={regionName}
            onChange={(e) => setRegionName(e.target.value)}
            inputProps={{ 'data-testid': 'budget:form:name' }}
            error={submitted && !regionName.trim().length}
          />
        </section>
        <section>
          {hasCountryError && submitted && (
            <SpacedAlert severity="error">
              <span data-testid="budget:form:validation:countries">
                {countryErrorMessage}
              </span>
            </SpacedAlert>
          )}
          <CountryContainer
            style={{ gridTemplateRows: `repeat(${numberOfRows}, 1fr)` }}
          >
            {listedCountries.map((countryState) => (
              <CountryInput
                key={countryState.iso2Code}
                readOnly={budgetGroup?.defaultRegion}
                countryState={countryState}
                onChange={handleChange}
              />
            ))}
          </CountryContainer>
        </section>
        <section>
          <PointsLabel>
            Amount employees should receive per year level:
          </PointsLabel>
          {hasLevelPointErrors && submitted && (
            <SpacedAlert severity="error">
              <span data-testid="budget:form:validation:points">
                {pointsErrorMessage}
              </span>
            </SpacedAlert>
          )}
          <PointsContainer>
            {levelPoints.map((levelPoint) => (
              <TextField
                key={levelPoint.rank}
                value={levelPoint.value}
                type="number"
                label={levelPoint.rank}
                inputProps={{
                  'data-testid': `budget:form:points:${levelPoint.rank}`,
                }}
                onChange={(e) =>
                  handleLevelPointsChange(levelPoint.rank, e.target.value)
                }
                error={levelPoint.errors?.length && submitted}
              />
            ))}
          </PointsContainer>
        </section>
        <FooterContainer alignment={budgetGroup ? 'space-between' : 'flex-end'}>
          {budgetGroup && !budgetGroup.defaultRegion && (
            <Button
              data-testid="budget:form:delete"
              type="button"
              onClick={() => setDeleteGroup(true)}
              color="secondary"
            >
              Delete Group
            </Button>
          )}
          <div>
            <Button
              data-testid="budget:form:cancel"
              type="button"
              color="secondary"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <FooterButton data-testid="budget:form:submit" type="submit">
              {budgetGroup ? 'Update Group' : 'Add Group'}
            </FooterButton>
          </div>
        </FooterContainer>
      </BasicForm>
      {deleteGroup && (
        <ConfirmationModal
          destroy
          open={deleteGroup}
          titleText="Are you sure?"
          dataTestId="deleteBudgetGroup"
          messageText={`Would you like to delete the group "${regionName}"?`}
          primaryButtonText="Delete group"
          onModalClose={() => setDeleteGroup(false)}
          onModalAction={onDelete}
        />
      )}
    </>
  )
}

export default BudgetGroupForm
