import {
  Box,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@octanner/prism-core'
import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { FormData } from './ActionForm'
import { EventOption } from './utils'

interface Props {
  control: Control<FormData>
  actionValue: EventOption
}

const ShippingInputs = ({ control, actionValue }: Props) => {
  const actionsAutoCheckedForInternationalShipping = [
    'AUTOSHIP_AWARD_ORDER',
    'REGISTER_INVITES',
    'REQUEST_MANAGER_PERSONAL_NOTE',
  ]
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
      }}
    >
      <Typography variant="h4">Shipping</Typography>
      <Controller
        name="addInternationalShippingOffset"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                disabled
                {...field}
                checked={
                  actionsAutoCheckedForInternationalShipping.includes(
                    actionValue?.value as string
                  )
                    ? true
                    : field.value
                }
              />
            }
            label="Add 7 days for international shipping"
            tabIndex={0}
          />
        )}
      />
      {actionValue.value === 'AUTOSHIP_AWARD_ORDER' && (
        <Box
          sx={{
            display: 'flex',
            width: '70%',
            gap: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
            data-testid="action-form:shipping-address"
          >
            <Controller
              name="shippingAddress"
              control={control}
              render={({ field }) => (
                <>
                  <FormLabel>Shipping address:</FormLabel>
                  <RadioGroup
                    aria-label="shippingAddress"
                    name="shippingAddress"
                    value={field.value}
                    onChange={field.onChange}
                  >
                    <FormControlLabel
                      value="WORK_1"
                      control={<Radio />}
                      label="Work 1"
                    />
                    <FormControlLabel
                      value="WORK_2"
                      control={<Radio />}
                      label="Work 2"
                    />
                    <FormControlLabel
                      value="HOME"
                      control={<Radio />}
                      label="Celebrant home"
                    />
                  </RadioGroup>
                </>
              )}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
            data-testid="action-form:attention-to"
          >
            <Controller
              name="attentionTo"
              control={control}
              render={({ field }) => (
                <>
                  <FormLabel>Attention to:</FormLabel>
                  <RadioGroup
                    aria-label="attentionTo"
                    name="attentionTo"
                    value={field.value}
                    onChange={field.onChange}
                  >
                    <FormControlLabel
                      value="MANAGER"
                      control={<Radio />}
                      label="Manager"
                    />
                    <FormControlLabel
                      value="CLIENT_PROVIDED"
                      control={<Radio />}
                      label="Client Provided"
                    />
                    <FormControlLabel
                      value="CELEBRANT"
                      control={<Radio />}
                      label="Celebrant"
                    />
                  </RadioGroup>
                </>
              )}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
            data-testid="action-form:billing-location"
          >
            <Controller
              name="billingLocation"
              control={control}
              render={({ field }) => (
                <>
                  <FormLabel>Billing location:</FormLabel>
                  <RadioGroup
                    aria-label="billingLocation"
                    name="billingLocation"
                    value={field.value}
                    onChange={field.onChange}
                  >
                    <FormControlLabel
                      value="BILLING_LOCATION_1"
                      control={<Radio />}
                      label="Billing location 1"
                    />
                    <FormControlLabel
                      value="BILLING_LOCATION_2"
                      control={<Radio />}
                      label="Billing location 2"
                    />
                  </RadioGroup>
                </>
              )}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default ShippingInputs
