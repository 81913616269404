import {
  Box,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@octanner/prism-core'
import React from 'react'
import { Control, Controller, FieldErrorsImpl } from 'react-hook-form'
import { FormData } from './ActionForm'

interface Props {
  lockInputs: boolean
  control: Control<FormData>
  errors: Partial<FieldErrorsImpl<FormData>>
}

const ScheduleInputs = ({ lockInputs, control, errors }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
      }}
    >
      <Typography variant="h3">Schedule</Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '24px',
        }}
      >
        <Controller
          name="startOffset"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              data-testid="action-form:start-offset"
              label="Days"
              type="number"
              InputProps={{
                readOnly: lockInputs,
              }}
            />
          )}
        />
        <Controller
          name="when"
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <Select
              {...field}
              data-testid="action-form:when-input"
              height="tall"
              error={!!errors.when}
              helperText={errors.when?.message}
              label="When"
              inputProps={{
                readOnly: lockInputs,
              }}
            >
              <MenuItem value={'before'}>Before</MenuItem>
              <MenuItem value={'after'}>After</MenuItem>
            </Select>
          )}
        />
        <Controller
          name="offsetFrom"
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <Select
              {...field}
              data-testid="action-form:offset-from"
              height="tall"
              defaultValue=""
              error={!!errors.offsetFrom}
              helperText={errors.offsetFrom?.message}
              label="Event"
              inputProps={{
                readOnly: lockInputs,
              }}
            >
              <MenuItem value={'CELEBRATION_DATE'}>Celebration date</MenuItem>
              <MenuItem value={'ANNIVERSARY_DATE'}>Anniversary date</MenuItem>
            </Select>
          )}
        />
      </Box>
    </Box>
  )
}

export default ScheduleInputs
