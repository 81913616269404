import { CelebrationLevelConfig } from '../common/models/CelebrationLevelConfig'
import { YbCelebrationLevelGroupConfigInput } from '../common/models/YbCelebrationLevelGroupConfigInput'

export const createLevelGroupConfigInput = (
  config: YbCelebrationLevelGroupConfigInput,
  celebrationLevelConfigs: CelebrationLevelConfig[] = []
) => {
  const upsertConfigs = config.celebrationLevelConfigs.map((upsertItem) => {
    const existingItem = celebrationLevelConfigs.find(
      (prevItem) => prevItem.rank === upsertItem.rank
    )
    return {
      ...upsertItem,
      ...(existingItem ? { id: existingItem.id } : {}),
    }
  })

  const uniqueConfigIds = upsertConfigs.map((upsertConfig) => upsertConfig.id)

  const deletedConfigs = celebrationLevelConfigs
    .filter(
      (celebrationLevelConfig) =>
        !uniqueConfigIds.includes(celebrationLevelConfig.id)
    )
    .map(({ id }) => ({ id, rank: null }))
  return [...deletedConfigs, ...upsertConfigs]
}
