import { TaskScheduleGroupCodeInput } from 'library/src/models/ActionScheduleTemplate'

export const eventOptions = [
  {
    id: 1,
    label: 'Access digital yearbook',
    value: 'NOTIFY_CELEBRANT_VIEW_YEARBOOK',
  },
  { id: 2, label: 'Accrual points deposit', value: 'DEPOSIT_ACCRUAL_POINTS' },
  {
    id: 3,
    label: 'Accrual points deposited notice',
    value: 'NOTIFY_CELEBRANT_ACCRUAL_POINTS_DEPOSITED',
  },
  { id: 4, label: 'Anniversary day event', value: 'ANNIVERSARY_DAY_EVENT' },
  { id: 5, label: 'Auto-ship order', value: 'AUTOSHIP_AWARD_ORDER' },
  {
    id: 6,
    label: 'Celebrant anniversary day',
    value: 'NOTIFY_CELEBRANT_ANNIVERSARY_DAY',
  },
  {
    id: 7,
    label: 'Celebrant certificate',
    value: 'NOTIFY_CELEBRANT_ANNIVERSARY_DAY_CERTIFICATE',
  },
  {
    id: 8,
    label: 'Celebrant personal note invite',
    value: 'NOTIFY_CELEBRANT_INVITE_PEERS',
  },
  { id: 9, label: 'Celebrant survey', value: 'REQUEST_CELEBRANT_FEEDBACK' },
  { id: 10, label: 'Celebration creation', value: 'CREATE_CELEBRATION' },
  { id: 11, label: 'Celebration end', value: 'CLOSE_CELEBRATION' },
  { id: 12, label: 'Display on social wall', value: 'DISPLAY_SOCIAL_WALL' },
  {
    id: 13,
    label: 'Display on anniversary list',
    value: 'DISPLAY_ON_ANNIVERSARY_LIST',
  },
  {
    id: 14,
    label: 'Manager anniversary day',
    value: 'NOTIFY_MANAGER_ANNIVERSARY_DAY',
  },
  {
    id: 15,
    label: 'Manager certificate',
    value: 'NOTIFY_MANAGER_ANNIVERSARY_DAY_CERTIFICATE',
  },
  {
    id: 16,
    label: 'Manager personal note',
    value: 'REQUEST_MANAGER_PERSONAL_NOTE',
  },
  {
    id: 17,
    label: 'Manager presentation',
    value: 'NOTIFY_MANAGER_PREPARE_PRESENTATION',
  },
  {
    id: 18,
    label: 'Notify admin of empty yearbook',
    value: 'NOTIFY_ADMIN_EMPTY_YEARBOOK',
  },
  {
    id: 19,
    label: 'Peer anniversary day',
    value: 'NOTIFY_PEERS_ANNIVERSARY_DAY',
  },
  { id: 20, label: 'Peer personal note', value: 'REQUEST_PEERS_PERSONAL_NOTE' },
  { id: 21, label: 'Point deposit', value: 'DEPOSIT_POINTS' },
  { id: 22, label: 'Points for service', value: 'POINTS_FOR_SERVICE' },
  {
    id: 23,
    label: 'Update celebration manager',
    value: 'UPDATE_CELEBRATION_MANAGER',
  },
  {
    id: 24,
    label: 'User selected gift',
    value: 'NOTIFY_CELEBRANT_ORDER_FROM_CATALOG',
  },
  {
    id: 25,
    label: 'Auto invites registered',
    value: 'REGISTER_INVITES',
  },
]

export type EventOption = {
  id: number
  label: string
  value: TaskScheduleGroupCodeInput | string
}
