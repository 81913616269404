import React from 'react'
import { Root } from './Styled'
import { NewCelebrationForm } from '@octanner/yearbook-program-settings'
import { useHistory } from 'react-router-dom'

type Props = { programId: string }

const NewCelebrationWrapper: React.FC<Props> = ({ programId }) => {
  const history = useHistory()
  const onReroute = () => () => {
    history.push(`/program-settings/${programId}`)
  }
  return (
    <Root>
      <NewCelebrationForm programId={programId} onReroute={onReroute()} />
    </Root>
  )
}

export default NewCelebrationWrapper
