import { CelebrationLevelGroupConfig } from '../common/models/CelebrationLevelGroupConfig'

export interface DetailViewModel {
  text: string
  checked?: boolean
  tag?: boolean
  children?: DetailViewModel[]
}

const factoryBuilder =
  (
    fn: (
      celebration: CelebrationLevelGroupConfig
    ) => DetailViewModel | undefined,
    condition?: (config: CelebrationLevelGroupConfig) => boolean
  ) =>
  (celebration: CelebrationLevelGroupConfig) => {
    if (condition && !condition(celebration)) return
    return fn(celebration)
  }

const getCoverType = (celebration: CelebrationLevelGroupConfig): string => {
  switch (celebration.printCoverType) {
    case 'HARD_BOUND':
      return 'Hardbound'
    case 'SOFT_BOUND':
      return 'Softbound'
  }
}

type GenericFactoryProps = {
  key: keyof CelebrationLevelGroupConfig
  text: string
  condition?: (config: CelebrationLevelGroupConfig) => boolean
  tag?: boolean
}

const genericFactory = ({
  key,
  text,
  condition,
  tag = false,
}: GenericFactoryProps) =>
  factoryBuilder((celebration) => {
    const value = celebration[key]
    if (value === 'undefined') return
    return {
      text,
      checked: Boolean(value),
      tag,
    }
  }, condition)

const requiresMilestone = (config: CelebrationLevelGroupConfig) =>
  Boolean(config.milestone)

const leaderFactory = factoryBuilder((celebration) => {
  const text = 'Leader personal notes'
  if (!celebration.leaderNotesEnabled) return { text, checked: false }
  return {
    text,
    checked: true,
  }
})

const peerFactory = factoryBuilder((celebration) => {
  const text = 'Peer personal notes'
  if (!celebration.peerNotesEnabled) return { text, checked: false }
  return {
    text,
    checked: true,
  }
})

const yearbookFactory = factoryBuilder((celebration) => {
  if (!celebration.yearbookEnabled)
    return { text: 'No yearbooks', checked: false }

  const printType = celebration.printCoverType
    ? [{ text: getCoverType(celebration), checked: true, tag: true }]
    : []
  return {
    text: celebration.printYearbookEnabled
      ? 'Print and digital'
      : 'Digital only',
    checked: true,
    tag: true,
    children: [...printType],
  }
}, requiresMilestone)

const giftFactory = factoryBuilder((celebration) => {
  if (
    !celebration.userSelectedOrder &&
    !celebration.accrualPointsForServiceEnabled
  )
    return
  if (celebration.userSelectedOrder) {
    return {
      text: 'User selected gift',
      checked: true,
      tag: true,
    }
  }
  if (celebration.accrualPointsForServiceEnabled) {
    return {
      text: 'Points for service',
      checked: true,
      tag: true,
    }
  }
}, requiresMilestone)

const emailFactory = factoryBuilder((c) => {
  if (c.milestone) return
  if (
    !c.milestone &&
    (c.celebrantCongratulatoryNotificationEnabled ||
      c.peersAnniversaryDayNotificationEnabled ||
      c.managerAnniversaryDayNotificationEnabled)
  ) {
    return { text: 'Anniversary emails', checked: true, tag: false }
  } else {
    return { text: 'Anniversary emails', checked: false, tag: false }
  }
})

const emailCertificateFactory = factoryBuilder((c) => {
  if (
    c.celebrantCertificateNotificationEnabled ||
    c.managerCertificateNotificationEnabled
  ) {
    return {
      text: 'Anniversary certificate',
      checked: true,
    }
  } else {
    return { text: 'Anniversary certificate', checked: false, tag: false }
  }
}, requiresMilestone)

const autoshipFactory = genericFactory({
  key: 'autoshipAwards',
  text: 'Auto-ship award',
  condition: requiresMilestone,
  tag: true,
})

const printedCertificatesFactory = genericFactory({
  key: 'printCertificateEnabled',
  text: 'Printed certificates',
  condition: requiresMilestone,
})

const presentationReminderFactory = genericFactory({
  key: 'presentationReminderEmail',
  text: 'Presentation reminder email',
  condition: requiresMilestone,
})

const surveyFactory = genericFactory({
  key: 'surveyEnabled',
  text: 'Survey',
  condition: requiresMilestone,
})
const emptyYearbookEmailFactory = genericFactory({
  key: 'emptyYearbookEmailEnabled',
  text: 'Email admin about empty yearbook',
  condition: requiresMilestone,
})

const celebrantInvitePeersEmailFactory = genericFactory({
  key: 'celebrantInvitePeersNotificationEnabled',
  text: 'Celebrant invite peers email',
  condition: requiresMilestone,
})

const celebrantCongratulatoryEmailFactory = genericFactory({
  key: 'celebrantCongratulatoryNotificationEnabled',
  text: 'Happy anniversary email - day of email',
  condition: requiresMilestone,
})

const peersAnniversaryDayEmailFactory = genericFactory({
  key: 'peersAnniversaryDayNotificationEnabled',
  text: 'Notify peers of anniversary - day of email',
  condition: requiresMilestone,
})

const leaderPhotoEnabledFactory = genericFactory({
  key: 'leaderPhotoEnabled',
  text: 'Leader photos',
})
const leaderVideoEnabledFactory = genericFactory({
  key: 'leaderVideoEnabled',
  text: 'Leader video',
})
const peerPhotoEnabledFactory = genericFactory({
  key: 'peerPhotoEnabled',
  text: 'Peer photos',
})
const peerVideoEnabledFactory = genericFactory({
  key: 'peerVideoEnabled',
  text: 'Peer video',
})
const autoInviteEnabledFactory = genericFactory({
  key: 'autoInviteEnabled',
  text: 'Auto invite',
})
const managerCertificateNotificationEnabledFactory = genericFactory({
  key: 'managerCertificateNotificationEnabled',
  text: 'Email to the manager',
})
const celebrantCertificateNotificationEnabledFactory = genericFactory({
  key: 'celebrantCertificateNotificationEnabled',
  text: 'Email to the celebrant',
})

export const rootFactories = [
  giftFactory,
  yearbookFactory,
  autoshipFactory,
  printedCertificatesFactory,
  emailFactory,
  celebrantInvitePeersEmailFactory,
  celebrantCongratulatoryEmailFactory,
  peersAnniversaryDayEmailFactory,
  emptyYearbookEmailFactory,
  presentationReminderFactory,
  emailCertificateFactory,
  surveyFactory,
  leaderFactory,
  peerFactory,
  leaderPhotoEnabledFactory,
  leaderVideoEnabledFactory,
  peerPhotoEnabledFactory,
  peerVideoEnabledFactory,
  autoInviteEnabledFactory,
  managerCertificateNotificationEnabledFactory,
  celebrantCertificateNotificationEnabledFactory,
]
