import { Button, TextField, Checkbox, Typography } from '@octanner/prism-core'
import { BudgetGroupFormContainer } from '@octanner/yearbook-program-settings'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Root } from './Styled'

type UpdateProps = { programId: string; budgetGroupId: string }
type NewProps = { programId: string; isNew: boolean }

type Props = UpdateProps | NewProps | {}

const isUpdatedProps = (object: any): object is UpdateProps =>
  'budgetGroupId' in object

const isNewProps = (object: any): object is NewProps => 'isNew' in object

const getProps = (props: Props) => {
  if (isUpdatedProps(props)) {
    const { programId, budgetGroupId } = props
    return { programId, budgetGroupId, isNew: false }
  }

  if (isNewProps(props)) {
    const { programId, isNew } = props
    return { programId, isNew }
  }
  return {}
}

const BudgetGroupWrapper: React.FC<Props> = (props) => {
  const history = useHistory()
  const { programId, budgetGroupId, isNew } = getProps(props)
  const [form, setForm] = useState({
    programId: programId || '',
    budgetGroupId: budgetGroupId || '',
    submitted: false,
    createMode: false,
  })

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    setForm((cur) => ({ ...cur, submitted: true }))
    if (!form.budgetGroupId || !form.programId) return
    if (form.createMode) {
      history.push(`/budget-settings/new/${form.programId}`)
      return
    }
    history.push(`/budget-settings/${form.programId}/${form.budgetGroupId}`)
  }

  const handleOnChange = () => {
    history.push(`/program-settings/${programId}`)
  }

  return (
    <Root>
      <Form onSubmit={handleSubmit}>
        <label style={{ display: 'flex' }} htmlFor="create-new">
          <Checkbox
            id="create-new"
            checked={form.createMode}
            onChange={(e) =>
              setForm((cur) => ({ ...cur, createMode: e.target.checked }))
            }
          />
          <Typography>New Budget Group</Typography>
        </label>
        <TextField
          label="Program Id"
          value={form.programId}
          onChange={(e) =>
            setForm((cur) => ({ ...cur, programId: e.target.value }))
          }
          error={form.submitted && !form.programId}
        />
        {!form.createMode && (
          <TextField
            label="Budget Group Id"
            value={form.budgetGroupId}
            onChange={(e) =>
              setForm((cur) => ({ ...cur, budgetGroupId: e.target.value }))
            }
            error={form.submitted && !form.budgetGroupId}
          />
        )}
        <Button type="submit">Submit</Button>
      </Form>
      {programId && (isNew || budgetGroupId) && (
        <BudgetGroupFormContainer
          onChange={handleOnChange}
          programId={programId}
          budgetGroupId={budgetGroupId}
        />
      )}
    </Root>
  )
}

export default BudgetGroupWrapper
