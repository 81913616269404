import {
  Button,
  TextField,
  DatePicker,
  AdapterLuxon,
  Typography,
  Box,
  BottomNavigation,
  SearchOptionCard,
  Alert,
} from '@octanner/prism-core'
import React, { useCallback, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import useCreateNewCelebration from '../hooks/useCreateNewCelebrations'
import { DateTime } from 'luxon'
import { ResultModal } from './ResultModal'
import { FormFieldHelper } from './FormFieldHelper'
import { useGetProgramOffsetsByProgramId } from '../hooks/useGetProgramOffsetsByProgramId'
import { IdentityBase } from '../models/CelebrationGroupEdit'
import IdentitySelector, {
  formatIdentityName,
} from '../common/components/IdentitySelector'
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors'
import CelebrationLevelSelector from './CelebrationLevelSelector'

type FormData = {
  identity: IdentityBase
  celebrationDate: DateTime
  celebrationLevel: number | null
}
type Props = {
  programId: string
  onReroute: () => void
}

const NewCelebrationForm: React.FC<Props> = ({ programId, onReroute }) => {
  const { data, loading } = useGetProgramOffsetsByProgramId(programId)

  const customerId = data?.program?.coreProgram?.customer?.id ?? ''
  const { control, handleSubmit, clearErrors } = useForm<FormData>({
    defaultValues: { celebrationDate: DateTime.now() },
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [indetitySearchError, setIdentitySearchError] = useState<{
    message: string
  } | null>(null)

  const { createNewCelebration, errorMessage, setAllErrors } =
    useCreateNewCelebration()

  const onSubmit = (data: FormData) => {
    createNewCelebration({
      input: [
        {
          identityUuid: data.identity.identityUuid,
          celebrationDate: data.celebrationDate
            .startOf('day')
            .toFormat('yyyy-LL-dd'),
          celebrationLevel: data.celebrationLevel,
          programUuid: programId,
        },
      ],
      onSettled: async () => {
        setIsModalOpen(true)
      },
    })
  }
  const handleModalClose = () => {
    setIsModalOpen(false)
    if (errorMessage.message.length === 0) {
      setAllErrors('')
      onReroute()
    }
    setAllErrors('')
  }

  const onSearchError = useCallback((error: { message: string }) => {
    setIdentitySearchError(error)
  }, [])

  if (loading) {
    return <Typography>Loading...</Typography>
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            width: '949px',
            marginLeft: '32px',
          }}
        >
          <Box>
            <Typography variant="h1" sx={{ pt: 4 }}>
              Add New Celebration
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <FormFieldHelper
              title="Select employee"
              subtitle="Choose the employee you want to create celebration for"
            />
            <Controller
              name="identity"
              control={control}
              rules={{
                required:
                  'You must have an employee selected in order to create this celebration.',
              }}
              render={({ field, fieldState }) => (
                <>
                  <IdentitySelector
                    {...field}
                    data-testid="new-celebration-identity-selector"
                    customerId={customerId}
                    identity={field.value}
                    placeholder="Search for employee"
                    onError={onSearchError}
                    renderOption={(props, option) => (
                      <SearchOptionCard
                        {...props}
                        data-testid={'identity-selector:option:' + option.id}
                        // @ts-ignore incorrect type in prism
                        title={
                          <span>
                            {formatIdentityName(option)}
                            <Typography
                              variant="body2"
                              color={tannerGray[600]}
                              component={'span'}
                            >{` • Employee ID: ${option.uniqueId}`}</Typography>
                          </span>
                        }
                        subTitle={`${option.email}  • UUID: ${option.identityUuid}`}
                        avatarUrl={option.profile.avatar}
                        showAvatar
                      />
                    )}
                  />
                  {(indetitySearchError || fieldState.error) && (
                    <Alert
                      onClose={() => {
                        setIdentitySearchError(null)
                        clearErrors('identity')
                      }}
                      severity="error"
                    >
                      {fieldState.error?.message ??
                        indetitySearchError?.message ??
                        ''}
                    </Alert>
                  )}
                </>
              )}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <FormFieldHelper
              title="Enter Celebration Details"
              subtitle="Choose the anniversary year and celebration date for which you want to create celebration"
            />
            <Controller
              name="celebrationLevel"
              control={control}
              rules={{ required: 'Required' }}
              render={({ field, fieldState }) => (
                <CelebrationLevelSelector
                  programId={programId}
                  onChange={field.onChange}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="celebrationDate"
              control={control}
              rules={{ required: 'Required' }}
              render={({ field, fieldState }) => (
                <DatePicker
                  {...field}
                  label="Celebration Date"
                  dateAdapter={AdapterLuxon}
                  disableMaskedInput
                  shouldDisableDate={(celebrationDate: DateTime) => {
                    const currentDate = DateTime.now().startOf('day')
                    return (
                      celebrationDate < currentDate ||
                      celebrationDate > currentDate.plus({ years: 1 })
                    )
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: '400px' }}
                      {...params}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Box>
        </Box>
        <BottomNavigation
          sx={{
            position: 'fixed',
            bottom: 0,
            py: '16px',
            px: '40px',
            boxShadow: '0px 4px 14px 0px #00000033',
            justifyContent: 'space-between',
            width: '100%',
            height: '72px',
            marginLeft: '-8px',
            zIndex: 1,
          }}
        >
          <Box sx={{ display: 'flex', gap: '16px' }}>
            <Button type="submit">Create Celebration</Button>
            <Button onClick={() => onReroute()} variant="text">
              Cancel
            </Button>
          </Box>
        </BottomNavigation>
      </form>
      <ResultModal
        open={isModalOpen}
        onClose={() => handleModalClose()}
        error={errorMessage}
        successMessage="Celebration has been created successfully. You can view the celebration details in the celebrations list."
      />
    </>
  )
}

export default NewCelebrationForm
