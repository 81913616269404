import events from '../utilities/events'
import { setContext } from '@apollo/client/link/context'
import { popItem, storeItem } from '../utilities/persistence'

export type InternalState = {
  token: string
  refreshToken: string
  decodedToken?: {
    iss: string
    sub: string
    aud: string
    azp: string
    rls: string[]
    cus: string
    sid: string
    xca?: number
    jti: string
    iat: number
    exp: number
  }
}

const STORAGE_KEY_PREFIX = 'auth'
// @ts-ignore
const { ssoUrl, clientId, loginRedirectUri, scope } = OAUTH_CONFIGURATION
// @ts-ignore
const crypto: Crypto = window.crypto || window.msCrypto
const internalState: InternalState = {
  token: '',
  refreshToken: '',
}
const base64Values =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
const curriedStoredItem = (key: string, value: any) =>
  storeItem(`${STORAGE_KEY_PREFIX}:${key}`, value)
const curriedPopItem = (key: string) => popItem(`${STORAGE_KEY_PREFIX}:${key}`)

export const isAuthenticated = () => Boolean(internalState.token)

export const authFetch = (input: RequestInfo, init?: RequestInit) =>
  fetch(input, {
    ...init,
    headers: {
      ...init?.headers,
      ...(internalState.token
        ? { Authorization: `Bearer ${internalState.token}` }
        : {}),
    },
  })

export const sha256Hash = async (
  message: string,
  useHex = false
): Promise<string> => {
  const encoder = new TextEncoder(),
    msgUint8 = encoder.encode(message),
    hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8)
  return useHex
    ? arrayBufferToHexString(hashBuffer)
    : arrayBufferToString(hashBuffer)
}

export const generateKey = (size = 32): string => {
  const array = new Uint8Array(size)
  crypto.getRandomValues(array)
  const sixBits = array.map((v) => v & 63),
    base64 = sixBits.reduce((acc, bits) => acc + base64Values[bits], '')
  return base64
}

export const arrayBufferToHexString = (buffer: ArrayBuffer): string => {
  const hashArray = Array.from(new Uint8Array(buffer))
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
}

export const arrayBufferToString = (buffer: ArrayBuffer): string => {
  const hashArray = Array.from(new Uint8Array(buffer))
  return hashArray.reduce((acc, val) => acc + String.fromCharCode(val), '')
}

export const base64Encode = (value: string): string => window.btoa(value)

export const base64UrlEncode = (value: string): string =>
  window.btoa(value).replace(/\+/g, '-').replace(/=+$/g, '').replace(/\//g, '_')

export const initiateLogin = async (): Promise<void> => {
  if (!ssoUrl || !loginRedirectUri || !clientId) {
    throw new Error('argument_null: ssoUrl || loginRedirectUri || clientId')
  }

  const codeVerifier = generateKey(128)
  const hash = await sha256Hash(codeVerifier)
  const encode = base64UrlEncode(hash)
  const state = base64UrlEncode(generateKey())
  const authorizeUrl = new URL('/sso/oauth/authorize', ssoUrl)
  authorizeUrl.searchParams.set('response_type', 'code')
  authorizeUrl.searchParams.set('redirect_uri', loginRedirectUri)
  authorizeUrl.searchParams.set('client_id', clientId)
  authorizeUrl.searchParams.set('state', state)
  authorizeUrl.searchParams.set('scope', scope)
  authorizeUrl.searchParams.set('code_challenge', encode)
  authorizeUrl.searchParams.set('code_challenge_method', 'S256')
  curriedStoredItem(`code`, codeVerifier)
  curriedStoredItem('state', state)
  window.location.href = authorizeUrl.href
}

export const exchangeCode = async (code: string, returnedState: string) => {
  if (!loginRedirectUri || !clientId) {
    throw new Error('argument_null: loginRedirectUri || clientId')
  }

  const codeVerifier = curriedPopItem('code')
  const state = curriedPopItem('state')

  if (returnedState !== state) {
    throw new Error(`oauth_state`)
  }

  const tokenUrl = new URL('/sso/oauth/token', window.location.origin)
  const body = {
    grant_type: 'authorization_code',
    code: code,
    client_id: clientId,
    redirect_uri: loginRedirectUri,
    code_verifier: codeVerifier,
  }

  try {
    const r = await fetch(tokenUrl.href, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      cache: 'no-cache',
    })

    const data = await r.json()
    const { access_token: accessToken, refresh_token: refreshToken } = data

    internalState.token = accessToken
    internalState.refreshToken = refreshToken
    internalState.decodedToken = JSON.parse(
      window.atob(accessToken.split('.')[1])
    )
    events.publish('auth', { decodedToken: internalState.decodedToken })
  } catch (e) {
    throw new Error(`code_exchange: ${e.message}`)
  }
}

export const apolloAuthLink = setContext(async () => {
  return {
    headers: {
      authorization: `Bearer ${internalState.token}`,
    },
  }
})
